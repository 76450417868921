import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import addPlugins from './plugins';
import createi18n from './plugins/i18n';
import addTcComponents from './plugins/tccomponents';
import './styles/styles.scss';
import { apolloProvider } from './plugins/vue-apollo';
import idsrvAuth from './plugins/vue-oidc-client/idsrvAuth';
import { datadogRum } from '@datadog/browser-rum';
datadogRum.init({
    applicationId: 'f0b96330-532e-4e40-980d-707e97fdc938',
    clientToken: 'pubfda029b8f6fe3fad963bc46a56749709',
    site: 'datadoghq.com',
    service: 'inspections',
    env: process.env.VUE_APP_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog 
    version: `V${process.env.VUE_APP_VERSION}`,
    sampleRate: 100,
    trackInteractions: false,
    trackViewsManually: true
});
addPlugins(Vue);
const { tcComponents } = addTcComponents(Vue);
Vue.config.productionTip = false;
const i18n = createi18n();
idsrvAuth.startup().then(ok => {
    if (ok) {
        new Vue({
            router,
            i18n: i18n,
            store,
            vuetify: tcComponents.vuetify,
            apolloProvider,
            render: h => h(App)
        }).$mount('#app');
    }
    else {
        console.log('Startup was not ok');
    }
});
export { i18n };
