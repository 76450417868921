import { __decorate } from "tslib";
import { TemplateInspectionUpdate } from "@/types/Api/Template/Update/TemplateInspectionUpdate";
import gql from "graphql-tag";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import DeleteButton from "../../DeleteButton.vue";
import MoveButton from "../../MoveButton.vue";
let NumericalInspectionEdit = class NumericalInspectionEdit extends Vue {
    showErrorIcon = false;
    editEnabled = false;
    titleClicked = false;
    value;
    hover;
    isMaxSmallerThanMin() {
        if (this.value.numericalInspection == null) {
            return false;
        }
        if (this.value.numericalInspection.minValue && this.value.numericalInspection.maxValue) {
            if (this.value.numericalInspection.maxValue <= this.value.numericalInspection.minValue) {
                return true;
            }
        }
        return false;
    }
    get supportTouch() {
        return ('ontouchstart' in window);
    }
    setEditMode(value) {
        if (!value && this.editEnabled) {
            this.titleClicked = false;
            this.doSave();
        }
        this.editEnabled = value;
    }
    onMinValueChanged(minValue) {
        if (minValue === '-' || minValue === '+')
            return;
        if (this.value.numericalInspection == null)
            return;
        if (minValue) {
            this.value.numericalInspection.minValue = Number.parseFloat(minValue);
        }
        else {
            this.value.numericalInspection.minValue = null;
        }
    }
    onMaxValueChanged(maxValue) {
        if (maxValue === '-' || maxValue === '+')
            return;
        if (this.value.numericalInspection == null)
            return;
        if (maxValue) {
            this.value.numericalInspection.maxValue = Number.parseFloat(maxValue);
        }
        else {
            this.value.numericalInspection.maxValue = null;
        }
    }
    onUnitValueChanged(unit) {
        if (this.value.numericalInspection == null)
            return;
        this.value.numericalInspection.unit = unit.trim();
    }
    doSave() {
        this.$promiseQueue.enqueue(() => {
            let updateObject = new TemplateInspectionUpdate(this.value);
            return new Promise((resolve, reject) => {
                this.$apollo.mutate({
                    mutation: gql `mutation ($input: UpdateTemplateInspectionInput!) 
          {
            updatedInspection: updateTemplateInspection(input: $input) {
              templateInspection {
                id
                sortOrder
              }
            }
          }`,
                    variables: {
                        input: { templateInspection: updateObject }
                    },
                    fetchPolicy: "no-cache"
                }).then(_ => {
                    resolve(this.value);
                }).catch(error => {
                    reject(error);
                });
            });
        }, {
            dataObject: this.value,
            description: this.$t('update-inspection-edit-description', [this.getDescription()]).toString(),
        }, true);
    }
    getDescription() {
        if (this.value.numericalInspection?.description)
            return this.value.numericalInspection.description;
        else
            return this.$t('inspectionTitle');
    }
    onBlur() {
        this.showErrorIcon = this.isMaxSmallerThanMin();
    }
    onKeyDown(event) {
        if (["Enter", "Esc", "Escape"].indexOf(event.key) > -1) {
            this.closeEditMode();
        }
    }
    closeEditMode() {
        this.setEditMode(false);
    }
    showEditMode() {
        this.setEditMode(true);
    }
    onDelete() {
        return true;
    }
};
__decorate([
    Prop({ type: Object, required: true, default: null })
], NumericalInspectionEdit.prototype, "value", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], NumericalInspectionEdit.prototype, "hover", void 0);
__decorate([
    Emit('delete')
], NumericalInspectionEdit.prototype, "onDelete", null);
NumericalInspectionEdit = __decorate([
    Component({
        components: { MoveButton, DeleteButton },
    })
], NumericalInspectionEdit);
export default NumericalInspectionEdit;
