export class TemplateInspection {
    id = 0;
    inspectionGroupId = 0;
    sortOrder = 0;
    booleanInspection = undefined;
    numericalInspection = undefined;
    imageInspection = undefined;
    singleLineInspection = undefined;
    multiLineInspection = undefined;
    choiceInspection = undefined;
    dateInspection = undefined;
    orderChanged = false;
}
