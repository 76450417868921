import { __decorate } from "tslib";
import { Prop, Component, Vue } from "vue-property-decorator";
let NoData = class NoData extends Vue {
    image;
    showButton;
};
__decorate([
    Prop({ type: String, required: true })
], NoData.prototype, "image", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], NoData.prototype, "showButton", void 0);
NoData = __decorate([
    Component({
        components: {},
    })
], NoData);
export default NoData;
