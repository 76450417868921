export class TemplateDateInspectionUpdate {
    id = 0;
    description = '';
    constructor(DateInspection) {
        if (DateInspection) {
            this.id = DateInspection.id;
            this.description = DateInspection.description;
        }
    }
}
