import { CollectionSegmentInfo } from "../CollectionSegmentInfo";
export class InspectionListCollectionSegment {
    items = [];
    pageInfo = new CollectionSegmentInfo();
    totalCount = 0;
}
export class TemplateCollectionSegment {
    items = [];
    pageInfo = new CollectionSegmentInfo();
    totalCount = 0;
}
export class TemplateChoiceTypeCollectionSegment {
    items = [];
    pageInfo = new CollectionSegmentInfo();
    totalCount = 0;
}
