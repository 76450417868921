import { __decorate } from "tslib";
import mainOidc from '@/plugins/vue-oidc-client/idsrvAuth';
import { Component, Emit, Vue } from 'vue-property-decorator';
import { Getter, Mutation, State } from 'vuex-class';
import { trackAction } from "@/store/Helpers/UserMonitor";
let UserMenu = class UserMenu extends Vue {
    setLanguageOverride;
    showDrawer;
    userLanguage;
    get version() {
        return 'v ' + process.env.VUE_APP_VERSION;
    }
    get userName() {
        return mainOidc.userProfile.name ?? '?';
    }
    get isMobile() {
        return this.$vuetify.breakpoint.mobile;
    }
    get isDesktop() {
        return !this.isMobile;
    }
    get isAuthenticated() {
        return mainOidc.isAuthenticated;
    }
    signout() {
        // clear old user data
        window.localStorage.clear();
        this.$router.push('signout');
    }
    setLanguage(lang) {
        this.setLanguageOverride(lang);
        trackAction(`Set language override ${lang}`);
    }
    isActiveLanguage(lang) {
        return this.userLanguage.startsWith(lang);
    }
    searchQueryChanged(q) {
        return q;
    }
};
__decorate([
    Mutation
], UserMenu.prototype, "setLanguageOverride", void 0);
__decorate([
    State
], UserMenu.prototype, "showDrawer", void 0);
__decorate([
    Getter
], UserMenu.prototype, "userLanguage", void 0);
__decorate([
    Emit('searchQueryChanged')
], UserMenu.prototype, "searchQueryChanged", null);
UserMenu = __decorate([
    Component({
        components: {},
    })
], UserMenu);
export default UserMenu;
