import { __decorate } from "tslib";
import { generateApiUrl } from "@/Helpers/BaseUrl";
import { clearErrors, handleCommonError } from '@/Helpers/ErrorHandlers';
import mainOidc from '@/plugins/vue-oidc-client/idsrvAuth';
import axios from 'axios';
import gql from "graphql-tag";
import { Component, Prop, Vue } from "vue-property-decorator";
import ImageBlobReduce from "image-blob-reduce";
import { trackAction } from "@/store/Helpers/UserMonitor";
let InspectionListAttachmentsAddButton = class InspectionListAttachmentsAddButton extends Vue {
    inspectionList;
    loading = false;
    async handleFileChange(e) {
        if (!e.target)
            return;
        try {
            this.loading = true;
            const files = e.target.files;
            if (files == null)
                return;
            clearErrors();
            if (files && files.length) {
                const rescaledFiles = [];
                const reducer = new ImageBlobReduce();
                for (let i = 0; i < files.length; i++) {
                    let fileName = files[i].name;
                    try {
                        const resized = await reducer.toBlob(files[i], { max: 3000 });
                        if (fileName.split('.').pop() == 'bmp') {
                            fileName = fileName.substr(0, fileName.lastIndexOf(".")) + ".png";
                        }
                        rescaledFiles.push(new File([resized], fileName));
                    }
                    catch (err) {
                        console.error(`Error rescaling image ${fileName}`);
                        console.error(err);
                    }
                }
                let size = 0;
                for (let idx = 0; idx < rescaledFiles.length; idx++) {
                    size += rescaledFiles[idx].size;
                }
                // check if file size is <= 20Mb
                if (size > 20 * 1024 * 1024) {
                    handleCommonError(this.$t('max-file-size-large').toString());
                    return;
                }
                if (e.target.validity.valid) {
                    this.$promiseQueue.enqueue(() => {
                        return new Promise((resolve, reject) => {
                            this.submitFile(rescaledFiles).then(result => {
                                resolve(files);
                            }).catch(error => {
                                reject(error);
                            });
                        });
                    }, {
                        description: this.$t('upload-attachments').toString(),
                        dataObject: files
                    });
                    //Reset input element, to be able to re-upload the same file
                    e.target.files = null;
                    e.target.value = '';
                }
            }
        }
        catch (error) {
            console.error(error);
        }
    }
    async submitFile(files) {
        trackAction('Upload inspectionlist attachment(s)');
        let formData = new FormData();
        for (var i = 0; i < files.length; i++) {
            formData.append(`files`, files[i], files[i].name);
        }
        try {
            const result = await axios
                .post(`${generateApiUrl('inspections/attachments')}/${encodeURIComponent(this.inspectionList.key)}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': mainOidc.isAuthenticated ? `Bearer ${mainOidc.accessToken}` : ''
                }
            });
            this.inspectionList.attachments = this.inspectionList.attachments.concat(result.data.imageAttachments);
            this.$apollo.getClient().writeFragment({
                id: `InspectionList:${this.inspectionList.id}`,
                fragment: gql `
        fragment current on InspectionList {
          attachments
        }`,
                data: {
                    attachments: this.inspectionList.attachments,
                    __typename: "InspectionList"
                }
            });
            this.loading = false;
        }
        catch (error) {
            console.error("Error uploading files");
            console.error(error);
            this.loading = false;
        }
    }
};
__decorate([
    Prop({ type: Object, default: null })
], InspectionListAttachmentsAddButton.prototype, "inspectionList", void 0);
InspectionListAttachmentsAddButton = __decorate([
    Component({
        components: {}
    })
], InspectionListAttachmentsAddButton);
export default InspectionListAttachmentsAddButton;
