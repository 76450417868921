import mainOidc from '@/plugins/vue-oidc-client/idsrvAuth';
export default function getUrl(path) {
    return process.env.VUE_APP_API_URL + path;
}
export function generateApiUrl(apiSuffix) {
    const tenantoverride = localStorage.getItem('tenantoverride') || '';
    const graphqlHttpHost = process.env.VUE_APP_GRAPHQL_HTTP_HOST || 'http://localhost:8071/';
    let link = graphqlHttpHost;
    if (!link.endsWith('/')) {
        link = link + '/';
    }
    link = link + tenantoverride;
    if (!link.endsWith('/')) {
        link = link + '/';
    }
    if (tenantoverride == '' && mainOidc.isAuthenticated && mainOidc.userProfile.org) {
        link = link + mainOidc.userProfile.org + '/';
    }
    return link + 'api/v1/' + apiSuffix;
}
