import { __decorate } from "tslib";
import AppBarResponsiveButton from "@/components/AppBarResponsiveButton.vue";
import SubmitIndicator from "@/components/SubmitIndicator.vue";
import InspectionListEditView from "@/components/InspectionList/InspectionListEditView.vue";
import { goBack } from "@/Helpers/NavigationHelpers";
import gql from "graphql-tag";
import { Component, Vue } from "vue-property-decorator";
import axios from 'axios';
import { generateApiUrl } from "@/Helpers/BaseUrl";
import mainOidc from '@/plugins/vue-oidc-client/idsrvAuth';
import { saveAs } from 'file-saver';
import { Getter } from "vuex-class";
let InspectionSearchEdit = class InspectionSearchEdit extends Vue {
    selectedInspectionList = null;
    userLanguage;
    isLoadingReport = false;
    goBack() {
        goBack();
    }
    async downloadReport() {
        if (!this.selectedInspectionList)
            return;
        this.isLoadingReport = true;
        try {
            const response = await axios
                .get(generateApiUrl(`inspections/Report?language=${this.userLanguage}&keys=${encodeURIComponent(this.selectedInspectionList.key)}`), {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': mainOidc.isAuthenticated ? `Bearer ${mainOidc.accessToken}` : ''
                }
            });
            const blob = new Blob([response.data]);
            saveAs(blob, `${this.selectedInspectionList.key}.pdf`);
        }
        finally {
            this.isLoadingReport = false;
        }
    }
};
__decorate([
    Getter
], InspectionSearchEdit.prototype, "userLanguage", void 0);
InspectionSearchEdit = __decorate([
    Component({
        components: {
            InspectionListEditView,
            AppBarResponsiveButton,
            SubmitIndicator
        },
        apollo: {
            selectedInspectionList: {
                query: gql `query inspectionlist($inspectionKey: String!) {
                  selectedInspectionList: inspectionList(inspectionListKey: $inspectionKey) {
                    id
                    key
                    submitted
                    description
                    attachments
                    groups {
                      id
                      inspectionListId
                      sortOrder
                      description
                      inspections {
                        id
                        inspectionGroupId
                        sortOrder
                        booleanInspection {
                          id
                          inspectionId
                          description
                          value
                        }
                        numericalInspection {
                          id
                          inspectionId
                          description
                          minValue
                          maxValue
                          unit
                          measurement
                        }
                        imageInspection {
                          description
                          inspectionId
                          id
                          fileName
                        }
                        singleLineInspection {
                          id
                          inspectionId
                          description
                          value
                        }
                        multiLineInspection {
                          id
                          inspectionId
                          description
                          value
                        }
                        choiceInspection {
                          id
                          inspectionId
                          description
                          choiceTypeId
                          valueId
                        }
                        dateInspection {
                          id
                          inspectionId
                          description
                          date
                        }
                      }
                    }
                  }
                }`,
                fetchPolicy: 'network-only',
                variables() {
                    return { inspectionKey: this.$route.params['key'] };
                }
            }
        }
    })
], InspectionSearchEdit);
export default InspectionSearchEdit;
