import { __decorate } from "tslib";
import { Component, Vue, Watch } from "vue-property-decorator";
import { State, Mutation } from 'vuex-class';
let ErrorBar = class ErrorBar extends Vue {
    showErrorBar = false;
    errors;
    clearErrors;
    onErrorsChange(val) {
        this.showErrorBar = val ? val.length > 0 : false;
    }
    dismissError() {
        this.clearErrors();
    }
};
__decorate([
    State
], ErrorBar.prototype, "errors", void 0);
__decorate([
    Mutation
], ErrorBar.prototype, "clearErrors", void 0);
__decorate([
    Watch('errors')
], ErrorBar.prototype, "onErrorsChange", null);
ErrorBar = __decorate([
    Component({
        components: {},
    })
], ErrorBar);
export default ErrorBar;
