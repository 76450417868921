export class TemplateUpdate {
    id = 0;
    key = '';
    description = '';
    constructor(template, deep = false) {
        this.id = template?.id ?? 0;
        this.key = template?.key ?? '';
        this.description = template?.description ?? '';
    }
}
