export default {
    light: {
        primary: '#414142',
        secondary: '#414142',
        background: '#EFF4F9',
        accent: '#5388e1',
        info: '#d7e0eb',
        'info-contrast': '#C0D1E5',
        error: '#A71B28',
        'error-lighten1': '#e18a8c',
        warning: '#f39152',
        'warning-lighten1': '#f9c8a8',
        success: '#129146',
        'success-lighten1': '#88c8a2',
        regular: '#626165',
        selection: '#268fd3',
        popup_form: '#f4f6f7',
        toggle_bg: '#d3d9e4',
        error_bg: '#efc0c0',
        error_text: '#414142',
        'tab-text': '#6FA7D7',
        'tab-bar': '#EFF4F9',
        'field-border-color': '#5388e1',
        'scrollbar-color': '#d7e0eb'
    }
};
