import { __decorate } from "tslib";
import { QueueState } from "@/plugins/promiseQueue/PromiseQueue";
import { trackAction } from "@/store/Helpers/UserMonitor";
import { differenceInMinutes } from "date-fns";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
let AutoSaveButton = class AutoSaveButton extends Vue {
    lastSaveDate = new Date(0);
    animationTimer;
    get error() {
        return this.$promiseQueue.hasErrors;
    }
    get loading() {
        return this.$promiseQueue.workingOnPromise;
    }
    get saveRequired() {
        return this.$promiseQueue.itemsInQueue > 0;
    }
    get itemsInQueue() {
        return this.$promiseQueue.itemsInQueue;
    }
    minutesSinceLastSave = 0;
    saveTooltipVisible = false;
    onItemsInQueueChanges(newValue, oldValue) {
        if (newValue == 0 && oldValue > 0) {
            this.lastSaveDate = new Date();
        }
    }
    onTooltipVisibleChanged(visible) {
        if (visible) {
            this.calculateMinutesSinceLastSave();
        }
    }
    getQueueIcon(queueItem) {
        switch (queueItem.state) {
            case QueueState.DONE:
                return "check";
            case QueueState.ERROR:
                return "cross3";
            case QueueState.PENDING:
                return "sync";
            default:
                return "clock3";
        }
    }
    isPending(queueItem) {
        return queueItem.state === QueueState.PENDING;
    }
    isError(queueItem) {
        return queueItem.state === QueueState.ERROR;
    }
    isDone(queueItem) {
        return queueItem.state === QueueState.DONE;
    }
    calculateMinutesSinceLastSave() {
        if (this.lastSaveDate > new Date(0)) {
            this.minutesSinceLastSave = differenceInMinutes(Date.now(), this.lastSaveDate);
            return;
        }
        this.minutesSinceLastSave = -1;
    }
    doManualSave() {
        trackAction("Retry save errors");
        this.$promiseQueue.retryErrors();
    }
};
__decorate([
    Prop({ type: Number, required: false, default: 3000 })
], AutoSaveButton.prototype, "animationTimer", void 0);
__decorate([
    Watch('itemsInQueue')
], AutoSaveButton.prototype, "onItemsInQueueChanges", null);
__decorate([
    Watch('saveTooltipVisible')
], AutoSaveButton.prototype, "onTooltipVisibleChanged", null);
AutoSaveButton = __decorate([
    Component({
        components: {}
    })
], AutoSaveButton);
export default AutoSaveButton;
