import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
let Unauthenticated = class Unauthenticated extends Vue {
};
Unauthenticated = __decorate([
    Component({
        components: {},
    })
], Unauthenticated);
export default Unauthenticated;
