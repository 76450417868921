import { __decorate } from "tslib";
import AutoSaveButton from "@/components/AutoSaveButton.vue";
import InspectionListEditForm from "@/components/InspectionList/InspectionListEditForm.vue";
import InspectionListEditSkeleton from "@/components/InspectionList/InspectionListEditSkeleton.vue";
import { Component, ModelSync, Vue } from "vue-property-decorator";
let InspectionListEditView = class InspectionListEditView extends Vue {
    inspectionListSync;
    get switchToFluid() {
        return this.$vuetify.breakpoint.width < (1700 - 24);
    }
};
__decorate([
    ModelSync('inspectionList', 'change', { type: Object, default: null })
], InspectionListEditView.prototype, "inspectionListSync", void 0);
InspectionListEditView = __decorate([
    Component({
        components: {
            InspectionListEditForm,
            InspectionListEditSkeleton,
            AutoSaveButton
        },
    })
], InspectionListEditView);
export default InspectionListEditView;
