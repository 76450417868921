import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
let TCHover = class TCHover extends Vue {
    hover = false;
    mouseMoved = false;
    hoverDisabled;
    onHoverDisabledChanged(newValue) {
        if (newValue) {
            this.hover = false;
            this.mouseMoved = false;
        }
    }
    onMouseEnter() {
        if (!this.hoverDisabled && this.mouseMoved) {
            this.hover = true;
        }
    }
    onMouseLeave() {
        this.hover = false;
    }
    onMouseMove() {
        this.mouseMoved = true;
        this.hover = true;
    }
};
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], TCHover.prototype, "hoverDisabled", void 0);
__decorate([
    Watch('hoverDisabled')
], TCHover.prototype, "onHoverDisabledChanged", null);
TCHover = __decorate([
    Component({
        components: {},
    })
], TCHover);
export default TCHover;
