import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, Emit, ModelSync, Prop, Vue, Watch } from "vue-property-decorator";
import copyLinkButton from '../CopyLinkButton.vue';
import { trackAction } from "@/store/Helpers/UserMonitor";
import ErrorBar from "../ErrorBar.vue";
import { Mutation } from 'vuex-class';
let InspectionListIdentifierDialog = class InspectionListIdentifierDialog extends Vue {
    showDialogValue;
    templateKey;
    setHideDefaultErrorBar;
    clearErrors;
    identifier = '';
    createdLinks = [];
    async mounted() {
        this.identifier = '';
    }
    get canSubmit() {
        if (this.identifier) {
            return true;
        }
        return false;
    }
    onVisibleChanged(value) {
        if (!value) {
            this.identifier = '';
            this.setHideDefaultErrorBar(false);
            this.clearErrors();
        }
        if (value) {
            this.setHideDefaultErrorBar(true);
            trackAction("Show create inspections list dialog");
        }
    }
    hide() {
        this.showDialogValue = false;
    }
    onIdentifierEntered() {
        return this.identifier;
    }
    createInstance() {
        const newIdentifier = this.identifier;
        this.$apollo.mutate({
            mutation: gql `mutation ($input: CreateTemplateInstanceInput!) {
        createdTemplateInstance: createTemplateInstance(input: $input) {
          string
          }
        }`,
            variables: {
                input: { templateKey: this.templateKey, inspectionListKey: newIdentifier }
            },
        }).then(result => {
            console.log(result.data.createdTemplateInstance.string);
            const newLink = this.$router.resolve({ name: 'InspectionSearchEdit', params: { key: newIdentifier } });
            var host = location.protocol.concat("//").concat(window.location.host);
            this.createdLinks.push({ key: newIdentifier, url: host.concat(newLink.href) });
            this.identifier = '';
            this.clearErrors();
            trackAction("Inspections list created");
        });
    }
    clearItems() {
        this.createdLinks = [];
    }
};
__decorate([
    ModelSync('showDialog', 'visiblityChanged', { type: Boolean, required: true })
], InspectionListIdentifierDialog.prototype, "showDialogValue", void 0);
__decorate([
    Prop({ type: String, required: true })
], InspectionListIdentifierDialog.prototype, "templateKey", void 0);
__decorate([
    Mutation
], InspectionListIdentifierDialog.prototype, "setHideDefaultErrorBar", void 0);
__decorate([
    Mutation
], InspectionListIdentifierDialog.prototype, "clearErrors", void 0);
__decorate([
    Watch('showDialogValue')
], InspectionListIdentifierDialog.prototype, "onVisibleChanged", null);
__decorate([
    Emit()
], InspectionListIdentifierDialog.prototype, "onIdentifierEntered", null);
InspectionListIdentifierDialog = __decorate([
    Component({
        components: {
            copyLinkButton,
            ErrorBar
        },
    })
], InspectionListIdentifierDialog);
export default InspectionListIdentifierDialog;
