import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import mainOidc from '@/plugins/vue-oidc-client/idsrvAuth';
let Home = class Home extends Vue {
    created() {
        //Perform redirect when the root url is loaded. This cannot be done from the router because that will generate an error:
        //Uncaught (in promise) Error: Redirected when going from "...." to "/" via a navigation guard.
        if (mainOidc.isAuthenticated) {
            const currentUserRoles = mainOidc.userProfile?.roles;
            if (currentUserRoles.indexOf("InspectionsEngineer") >= 0) {
                this.$router.push({ name: 'Templates' });
            }
            else {
                this.$router.push({ name: 'Inspections' });
            }
        }
    }
};
Home = __decorate([
    Component({
        components: {},
    })
], Home);
export default Home;
