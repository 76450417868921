import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import VueApollo from 'vue-apollo';
import { ApolloLink } from 'apollo-link';
import mainOidc from './vue-oidc-client/idsrvAuth';
import { handleGraphError } from '@/Helpers/ErrorHandlers';
import { onError } from 'apollo-link-error';
function generateGraphQLLink() {
    const tenantoverride = localStorage.getItem('tenantoverride') || '';
    const graphqlHttpHost = process.env.VUE_APP_GRAPHQL_HTTP_HOST || 'http://localhost:8071/';
    let link = graphqlHttpHost;
    if (!link.endsWith('/')) {
        link = link + '/';
    }
    link = link + tenantoverride;
    if (!link.endsWith('/')) {
        link = link + '/';
    }
    if (tenantoverride == '' && mainOidc.isAuthenticated && mainOidc.userProfile.org) {
        link = link + mainOidc.userProfile.org + '/';
    }
    return link + 'api/graphql';
}
const graphqlLink = generateGraphQLLink();
const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext({
        uri: generateGraphQLLink(),
        headers: {
            authorization: mainOidc.isAuthenticated ? `Bearer ${mainOidc.accessToken}` : null
        }
    });
    return forward(operation);
});
const httpLink = createHttpLink({
    uri: graphqlLink,
});
const cache = new InMemoryCache();
const errorLink = onError(error => {
    handleGraphError(error);
});
const apolloClient = new ApolloClient({
    link: authMiddleware.concat(errorLink).concat(httpLink),
    cache,
});
export const apolloProvider = new VueApollo({
    defaultClient: apolloClient
});
export function addVueApolloPlugin(Vue) {
    Vue.use(VueApollo);
}
