import { __decorate } from "tslib";
import AppBarResponsiveButton from "@/components/AppBarResponsiveButton.vue";
import AutoSaveButton from "@/components/AutoSaveButton.vue";
import InspectionListIdentifierDialog from "@/components/InspectionList/InspectionListIdentifierDialog.vue";
import TemplateEditMenu from "@/components/Templates/TemplateEditMenu.vue";
import TemplateEditView from "@/components/Templates/TemplateEditView.vue";
import { goBack } from "@/Helpers/NavigationHelpers";
import gql from 'graphql-tag';
import { Component, Vue } from "vue-property-decorator";
let TemplateEdit = class TemplateEdit extends Vue {
    selectedTemplate = null;
    showIdentifierInputDialog = false;
    isLoading = 0;
    goBack() {
        goBack();
    }
    showIdentifierInput() {
        this.showIdentifierInputDialog = true;
    }
};
TemplateEdit = __decorate([
    Component({
        components: {
            TemplateEditView,
            AppBarResponsiveButton,
            TemplateEditMenu,
            InspectionListIdentifierDialog,
            AutoSaveButton
        },
        apollo: {
            selectedTemplate: {
                loadingKey: "isLoading",
                query: gql `query template($templateKey: String!) {
                  selectedTemplate: template(templateKey: $templateKey) {
                    id
                    description
                    key
                    groups {
                      description
                      sortOrder
                      templateId
                      id
                      inspections {
                        id
                        inspectionGroupId
                        sortOrder
                        booleanInspection {
                          description
                          id
                        }
                        numericalInspection {
                          description
                          id
                          minValue
                          maxValue
                          unit
                        }
                        imageInspection {
                          description
                          id
                          fileName
                        }
                        singleLineInspection {
                          description
                          id
                        }
                        multiLineInspection {
                          description
                          id
                        }
                        choiceInspection {
                          description
                          templateChoiceTypeId
                          id
                        }
                        dateInspection {
                          description
                          id
                        }
                      }
                    }
                  }
                }`,
                fetchPolicy: 'network-only',
                variables() {
                    return { templateKey: this.$route.params['key'] };
                }
            }
        }
    })
], TemplateEdit);
export default TemplateEdit;
