import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
let BooleanInspectionComponent = class BooleanInspectionComponent extends Vue {
    value;
    onChange() {
        return this.value;
    }
};
__decorate([
    Prop({ type: Object, required: true, default: null })
], BooleanInspectionComponent.prototype, "value", void 0);
__decorate([
    Emit('change')
], BooleanInspectionComponent.prototype, "onChange", null);
BooleanInspectionComponent = __decorate([
    Component({
        components: {},
    })
], BooleanInspectionComponent);
export default BooleanInspectionComponent;
