export class TemplateNumericalInspectionUpdate {
    id = 0;
    description = '';
    minValue = null;
    maxValue = null;
    unit = '';
    constructor(numericalInspection) {
        if (numericalInspection) {
            this.id = numericalInspection.id;
            this.description = numericalInspection.description;
            this.minValue = numericalInspection.minValue;
            this.maxValue = numericalInspection.maxValue;
            this.unit = numericalInspection.unit;
        }
    }
}
