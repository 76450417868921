import { __decorate } from "tslib";
import AppBar from "@/components/AppBar.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import NoData from "../NoData.vue";
import ChoiceTypeEditForm from "./ChoiceTypeEditForm.vue";
import ChoiceTypeEditSkeleton from "./ChoiceTypeEditSkeleton.vue";
let ChoiceTypeEditView = class ChoiceTypeEditView extends Vue {
    choiceType;
    isLoading;
};
__decorate([
    Prop({ type: Object, default: null })
], ChoiceTypeEditView.prototype, "choiceType", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ChoiceTypeEditView.prototype, "isLoading", void 0);
ChoiceTypeEditView = __decorate([
    Component({
        components: {
            NoData,
            AppBar,
            ChoiceTypeEditForm,
            ChoiceTypeEditSkeleton
        },
    })
], ChoiceTypeEditView);
export default ChoiceTypeEditView;
