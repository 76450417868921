import { __decorate } from "tslib";
import AppBarResponsiveButton from "@/components/AppBarResponsiveButton.vue";
import TrivestSearchField from '@/components/TrivestComponents/TrivestSearchField/TrivestSearchField.vue';
import InspectionsSearch from "@/components/InspectionList/InspectionsSearch.vue";
import InspectionListHighlighter from '@/components/InspectionList/InspectionListHighlighter.vue';
import { InspectionListCollectionSegment } from "@/types/Api/Template/TemplateCollectionSegments";
import NoData from "@/components/NoData.vue";
import gql from "graphql-tag";
import { Component, Vue, Watch } from "vue-property-decorator";
import { trackAction } from "@/store/Helpers/UserMonitor";
const PAGESIZE = 20;
const PAGESIZELOCALSTORAGEKEY = 'pagesize';
let Inspections = class Inspections extends Vue {
    isLoading = 0;
    inspectionListCollection = new InspectionListCollectionSegment();
    inspectionKey = '';
    searchQuery = '';
    searchTimeoutID = 0;
    onSearchQueryChange(val, oldVal) {
        if (this.searchTimeoutID > 0) {
            window.clearTimeout(this.searchTimeoutID);
        }
        this.searchTimeoutID = window.setTimeout(() => {
            this.inspectionKey = this.searchQuery || '';
            trackAction("Search inspections");
        }, 500);
    }
    fetchMore() {
        this.$apollo.queries.inspectionListCollection.fetchMore({
            variables: {
                skip: this.inspectionListCollection.items.length
            }, updateQuery: (previousResult, { fetchMoreResult }) => {
                fetchMoreResult.inspectionListCollection.items = [...previousResult.inspectionListCollection.items, ...fetchMoreResult.inspectionListCollection.items];
                return fetchMoreResult;
            }
        });
    }
};
__decorate([
    Watch('searchQuery')
], Inspections.prototype, "onSearchQueryChange", null);
Inspections = __decorate([
    Component({
        components: {
            TrivestSearchField,
            AppBarResponsiveButton,
            InspectionListHighlighter,
            InspectionsSearch,
            NoData
        },
        apollo: {
            inspectionListCollection: {
                query: gql `query inspectionListCollection($skip: Int!, $take: Int!, $inspectionKey: String){
        inspectionListCollection: inspectionLists(skip: $skip, take: $take
          where: {
            or: [ { key: { contains: $inspectionKey } }]
          }) {
            items {
              id
              description
              submitted
              key
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            totalCount
          }
        }`,
                variables() {
                    return {
                        skip: 0,
                        take: localStorage.getItem(PAGESIZELOCALSTORAGEKEY) || PAGESIZE,
                        inspectionKey: this.inspectionKey
                    };
                },
                fetchPolicy: 'cache-and-network',
                loadingKey: 'isLoading'
            }
        }
    })
], Inspections);
export default Inspections;
