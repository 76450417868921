export class TemplateBooleanInspectionUpdate {
    id = 0;
    description = '';
    constructor(booleanInspection) {
        if (booleanInspection) {
            this.id = booleanInspection.id;
            this.description = booleanInspection.description;
        }
    }
}
