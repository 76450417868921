import { __decorate } from "tslib";
import { generateApiUrl } from '@/Helpers/BaseUrl';
import mainOidc from '@/plugins/vue-oidc-client/idsrvAuth';
import { Component, Prop, Vue } from "vue-property-decorator";
let ImageInspectionComponent = class ImageInspectionComponent extends Vue {
    value;
    inspectionListKey;
    url = '';
    loadingImage = false;
    mounted() {
        this.getFile();
    }
    getFile() {
        if (!this.value.imageInspection?.fileName) {
            return;
        }
        this.loadingImage = true;
        const downloadUrl = `${generateApiUrl('inspections')}/${encodeURIComponent(this.inspectionListKey)}/inspections/image/${encodeURIComponent(this.value.imageInspection.fileName)}`;
        if (downloadUrl) {
            let headers = new Headers();
            headers.append('Authorization', mainOidc.isAuthenticated ? `Bearer ${mainOidc.accessToken}` : '');
            fetch(downloadUrl, { headers })
                .then(response => response.blob())
                .then((blobby) => {
                this.url = URL.createObjectURL(blobby);
                this.loadingImage = false;
            })
                .catch((err) => {
                console.error(err);
                this.url = '';
                this.loadingImage = false;
            });
        }
        else {
            this.url = '';
        }
    }
    hideImage() {
        this.url = '';
    }
};
__decorate([
    Prop({ type: Object, required: true, default: null })
], ImageInspectionComponent.prototype, "value", void 0);
__decorate([
    Prop({ type: String, required: true })
], ImageInspectionComponent.prototype, "inspectionListKey", void 0);
ImageInspectionComponent = __decorate([
    Component({
        components: {},
    })
], ImageInspectionComponent);
export default ImageInspectionComponent;
