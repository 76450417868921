import { __decorate } from "tslib";
import { Component, Emit, ModelSync, Vue } from "vue-property-decorator";
let InspectionTitleEdit = class InspectionTitleEdit extends Vue {
    editEnabled = false;
    error = "";
    valueSync;
    setEditMode(value) {
        this.editEnabled = value;
    }
    onKeyDown(event) {
        if (["Enter", "Esc", "Escape"].indexOf(event.key) > -1) {
            this.closeEditMode();
        }
    }
    closeEditMode() {
        if (this.editEnabled) {
            this.validate();
            this.setEditMode(false);
        }
    }
    showEditMode() {
        this.setEditMode(true);
    }
    onDelete() {
        return true;
    }
    validate() {
        if (!this.valueSync) {
            this.error = this.$t('titleIsRequired').toString();
            return [this.error];
        }
        else {
            this.error = '';
        }
        return [];
    }
};
__decorate([
    ModelSync('value', 'change', { type: String, default: null })
], InspectionTitleEdit.prototype, "valueSync", void 0);
__decorate([
    Emit('delete')
], InspectionTitleEdit.prototype, "onDelete", null);
InspectionTitleEdit = __decorate([
    Component({
        components: {},
    })
], InspectionTitleEdit);
export default InspectionTitleEdit;
