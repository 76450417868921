export class InspectionListChoiceInspectionUpdate {
    id = 0;
    valueId = undefined;
    constructor(choiceInspection) {
        if (choiceInspection) {
            this.id = choiceInspection.id;
            if (choiceInspection.valueId)
                this.valueId = choiceInspection.valueId;
        }
    }
}
