export class TemplateChoiceValueInput {
    value = '';
    sortOrder = 0;
    templateChoiceTypeId = 0;
    constructor(templateChoiceValue) {
        if (templateChoiceValue) {
            this.value = templateChoiceValue.value;
            this.sortOrder = templateChoiceValue.sortOrder;
            this.templateChoiceTypeId = templateChoiceValue.templateChoiceTypeId;
        }
    }
}
