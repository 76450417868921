export class TemplateMultiLineInspectionUpdate {
    id = 0;
    description = '';
    constructor(MultiLineInspection) {
        if (MultiLineInspection) {
            this.id = MultiLineInspection.id;
            this.description = MultiLineInspection.description;
        }
    }
}
