import { __decorate } from "tslib";
import DefaultMenu from "@/components/DefaultMenu.vue";
import InspectionListList from "@/components/InspectionList/InspectionListList.vue";
import { InspectionListCollectionSegment } from "@/types/Api/Template/TemplateCollectionSegments";
import gql from 'graphql-tag';
import { Component, Prop, Vue } from "vue-property-decorator";
const PAGESIZE = 20;
const PAGESIZELOCALSTORAGEKEY = 'pagesize';
let InspectionListView = class InspectionListView extends Vue {
    isLoading = 0;
    inspectionListCollection = new InspectionListCollectionSegment();
    searchQuery;
    get query() {
        if (!this.searchQuery)
            return null;
        return this.searchQuery;
    }
    fetchMore() {
        this.$apollo.queries.inspectionListCollection.fetchMore({
            variables: {
                skip: this.inspectionListCollection.items.length
            }, updateQuery: (previousResult, { fetchMoreResult }) => {
                fetchMoreResult.inspectionListCollection.items = [...previousResult.inspectionListCollection.items, ...fetchMoreResult.inspectionListCollection.items];
                return fetchMoreResult;
            }
        });
    }
};
__decorate([
    Prop({ type: String, required: false, default: "" })
], InspectionListView.prototype, "searchQuery", void 0);
InspectionListView = __decorate([
    Component({
        components: {
            InspectionListList,
            DefaultMenu
        },
        apollo: {
            inspectionListCollection: {
                query: gql `query inspectionListCollection($skip: Int!, $take: Int!, $query: String) {
                    inspectionListCollection: inspectionLists(skip: $skip, take: $take
                  where: {
                    or: [ { key: { contains: $query } } ]
                  }) {
                    items {
                      id
                      description
                      submitted
                      key
                    }
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    totalCount
                  }
                }`,
                variables() {
                    return {
                        skip: 0,
                        take: localStorage.getItem(PAGESIZELOCALSTORAGEKEY) || PAGESIZE,
                        query: this.query
                    };
                },
                fetchPolicy: 'cache-and-network',
                loadingKey: 'isLoading'
            }
        }
    })
], InspectionListView);
export default InspectionListView;
