import { __decorate } from "tslib";
import BooleanInspectionComponent from "@/components/InspectionEntries/BooleanInspection/BooleanInspection.vue";
import NumericalInspectionComponent from "@/components/InspectionEntries/NumericalInspection/NumericalInspection.vue";
import ImageInspectionComponent from "@/components/InspectionEntries/ImageInspection/ImageInspection.vue";
import SingleLineInspectionComponent from "../InspectionEntries/TextInspection/SingleLineTextInspection.vue";
import MultiLineTextInspectionComponent from "../InspectionEntries/TextInspection/MultiLineTextInspection.vue";
import ChoiceInspectionComponent from "../InspectionEntries/ChoiceInspection/ChoiceInspection.vue";
import DateInspectionComponent from "../InspectionEntries/DateInspection/DateInspection.vue";
import InspectionTitle from "@/components/InspectionList/InspectionTitle.vue";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { orderBy } from "lodash";
let InspectionListGroup = class InspectionListGroup extends Vue {
    inspectionListGroup;
    inspectionListKey;
    onChange(inspection) {
        return inspection;
    }
    get sortedInspections() {
        return orderBy(this.inspectionListGroup.inspections, ['sortOrder', 'id'], ['asc', 'asc']);
    }
};
__decorate([
    Prop({ type: Object, default: null })
], InspectionListGroup.prototype, "inspectionListGroup", void 0);
__decorate([
    Prop({ type: String, required: true })
], InspectionListGroup.prototype, "inspectionListKey", void 0);
__decorate([
    Emit('change')
], InspectionListGroup.prototype, "onChange", null);
InspectionListGroup = __decorate([
    Component({
        components: {
            BooleanInspectionComponent,
            NumericalInspectionComponent,
            ImageInspectionComponent,
            SingleLineInspectionComponent,
            MultiLineTextInspectionComponent,
            ChoiceInspectionComponent,
            DateInspectionComponent,
            InspectionTitle
        }
    })
], InspectionListGroup);
export default InspectionListGroup;
