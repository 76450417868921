import { __decorate } from "tslib";
import AppBar from "@/components/AppBar.vue";
import ErrorBar from "@/components/ErrorBar.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter, Mutation, State } from "vuex-class";
import AutoSaveButton from "./components/AutoSaveButton.vue";
let App = class App extends Vue {
    drawer = false;
    searchQuery = "";
    showDrawer;
    hideDefaultErrorBar;
    setShowDrawer;
    userLanguage;
    onLanguageChange(newVal, oldVal) {
        this.$root.$i18n.locale = newVal;
    }
    mounted() {
        this.$root.$i18n.locale = this.userLanguage;
    }
    get shouldShowDrawer() {
        return (this.showDrawer || !this.$vuetify.breakpoint.mobile);
    }
    set shouldShowDrawer(show) {
        this.setShowDrawer(show);
    }
    onSearchQueryChanged(q) {
        this.searchQuery = q;
    }
    get canRouteShowDrawer() {
        return !this.$route.meta?.hideDrawer;
    }
    get showAutoSaveButton() {
        if (this.$route.meta?.alwaysShowSaveButton) {
            return true;
        }
        else {
            return this.$promiseQueue.itemsInQueue > 0;
        }
    }
};
__decorate([
    State
], App.prototype, "showDrawer", void 0);
__decorate([
    State
], App.prototype, "hideDefaultErrorBar", void 0);
__decorate([
    Mutation
], App.prototype, "setShowDrawer", void 0);
__decorate([
    Getter
], App.prototype, "userLanguage", void 0);
__decorate([
    Watch('userLanguage')
], App.prototype, "onLanguageChange", null);
App = __decorate([
    Component({
        components: {
            ErrorBar,
            AppBar,
            AutoSaveButton
        }
    })
], App);
export default App;
