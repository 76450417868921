export class TemplateNumericalInspectionInput {
    description = '';
    minValue = null;
    maxValue = null;
    unit = '';
    constructor(numericalInspection) {
        if (numericalInspection) {
            this.description = numericalInspection.description;
            this.minValue = numericalInspection.minValue;
            this.maxValue = numericalInspection.maxValue;
            this.unit = numericalInspection.unit;
        }
    }
}
