import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
let UndoSubmitButton = class UndoSubmitButton extends Vue {
    submitted;
    small;
    showBackgroundCircle;
    elevateOnHover;
    submitUndone = false;
    onClearSubmitState() {
        this.submitUndone = true;
    }
};
__decorate([
    Prop({ type: Boolean, required: true, default: false })
], UndoSubmitButton.prototype, "submitted", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], UndoSubmitButton.prototype, "small", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], UndoSubmitButton.prototype, "showBackgroundCircle", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], UndoSubmitButton.prototype, "elevateOnHover", void 0);
__decorate([
    Emit('ClearSubmitState')
], UndoSubmitButton.prototype, "onClearSubmitState", null);
UndoSubmitButton = __decorate([
    Component({
        components: {},
    })
], UndoSubmitButton);
export default UndoSubmitButton;
