import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
let InspectionListTitle = class InspectionListTitle extends Vue {
    value;
    onChange() {
        return this.value;
    }
};
__decorate([
    Prop({ type: String, required: true, default: null })
], InspectionListTitle.prototype, "value", void 0);
__decorate([
    Emit('change')
], InspectionListTitle.prototype, "onChange", null);
InspectionListTitle = __decorate([
    Component({
        components: {},
    })
], InspectionListTitle);
export default InspectionListTitle;
