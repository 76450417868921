import { render, staticRenderFns } from "./UndoSubmitButton.vue?vue&type=template&id=78466973&scoped=true&"
import script from "./UndoSubmitButton.vue?vue&type=script&lang=ts&"
export * from "./UndoSubmitButton.vue?vue&type=script&lang=ts&"
import style0 from "./UndoSubmitButton.vue?vue&type=style&index=0&id=78466973&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78466973",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VHover,VIcon,VSnackbar,VTooltip})
