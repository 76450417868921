export class TemplateImageInspectionUpdate {
    id = 0;
    description = '';
    constructor(imageInspection) {
        if (imageInspection) {
            this.id = imageInspection.id;
            this.description = imageInspection.description;
        }
    }
}
