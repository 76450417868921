import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import SkeletonEditField from "@/components/SkeletonEditField.vue";
let InspectionListItemSkeleton = class InspectionListItemSkeleton extends Vue {
};
InspectionListItemSkeleton = __decorate([
    Component({
        components: {
            SkeletonEditField
        },
    })
], InspectionListItemSkeleton);
export default InspectionListItemSkeleton;
