import store from '@/store';
import { i18n } from '@/main';
function translateKnownErrors(code) {
    // eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys
    return i18n.t(`error.${code}`).toString();
}
export function handleGraphError(error) {
    if (error.graphQLErrors) {
        error.graphQLErrors.forEach(element => {
            if (element.extensions && element.extensions['code']) {
                const code = element.extensions['code'];
                const message = translateKnownErrors(code);
                store.commit('addError', message);
            }
            else {
                store.commit('addError', element.message);
            }
        });
    }
    else if (error.networkError) {
        store.commit('addError', `${error.networkError.name}: ${error.networkError.message}`);
    }
    else {
        store.commit('addError', 'Unkown error');
    }
}
export function handleCommonError(error) {
    store.commit('addError', `${error}`);
}
export function clearErrors() {
    store.commit('clearErrors');
}
