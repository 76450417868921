import { __decorate } from "tslib";
import gql from "graphql-tag";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DeleteButton from "../../DeleteButton.vue";
import MoveButton from "../../MoveButton.vue";
import _, { isNumber } from 'lodash';
let TemplateChoiceValueAutoComplete = class TemplateChoiceValueAutoComplete extends Vue {
    templateChoiceTypeId;
    selectedChoiceType = null;
    isLoading = 0;
    onChoiceTypeChanged() {
        this.selectedChoiceType = null;
    }
    get shouldSkip() {
        return this.templateChoiceTypeId == 0 || !isNumber(this.templateChoiceTypeId) || isNaN(this.templateChoiceTypeId);
    }
    get sortedValues() {
        if (this.selectedChoiceType)
            return _.orderBy(this.selectedChoiceType.choiceValues, ['sortOrder', 'id']);
        else
            return [];
    }
};
__decorate([
    Prop({ type: Number, required: true, default: null })
], TemplateChoiceValueAutoComplete.prototype, "templateChoiceTypeId", void 0);
__decorate([
    Watch('templateChoiceTypeId')
], TemplateChoiceValueAutoComplete.prototype, "onChoiceTypeChanged", null);
TemplateChoiceValueAutoComplete = __decorate([
    Component({
        components: { MoveButton, DeleteButton },
        apollo: {
            selectedChoiceType: {
                loadingKey: "isLoading",
                query: gql `query templateChoiceType($typeId: Int!) {
                  selectedChoiceType: templateChoiceType(templateChoiceTypeId: $typeId) {
                    id
                    description
                    choiceValues {
                      templateChoiceTypeId
                      value
                      sortOrder
                      id
                    }
                  }
                }`,
                fetchPolicy: 'network-only',
                skip() {
                    return this.shouldSkip;
                },
                variables() {
                    return { typeId: Number(this.templateChoiceTypeId) };
                }
            }
        }
    })
], TemplateChoiceValueAutoComplete);
export default TemplateChoiceValueAutoComplete;
