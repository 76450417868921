export class InspectionListMultiLineInspectionUpdate {
    id = 0;
    value = undefined;
    constructor(multiLineInspection) {
        if (multiLineInspection) {
            this.id = multiLineInspection.id;
            this.value = multiLineInspection.value;
        }
    }
}
