import { __decorate } from "tslib";
import { TemplateChoiceTypeCollectionSegment } from "@/types/Api/Template/TemplateCollectionSegments";
import gql from "graphql-tag";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DeleteButton from "../../DeleteButton.vue";
import MoveButton from "../../MoveButton.vue";
const PAGESIZE = 20;
const PAGESIZELOCALSTORAGEKEY = 'pagesize';
let ChoiceTypeAutoComplete = class ChoiceTypeAutoComplete extends Vue {
    selectedId;
    templateChoiceTypeCollection = new TemplateChoiceTypeCollectionSegment();
    selectedItem = null;
    isLoadingChoiceTypes = false;
    searchInput = null;
    searchTimeoutID = 0;
    searchQuery = '';
    onChoiceListChanged() {
        console.log('checking selected item');
        if (this.selectedId) {
            let selectedItem = this.templateChoiceTypeCollection.items.find(item => item.id == this.selectedId);
            if (!selectedItem) {
                this.fetchMore();
            }
            else {
                this.selectedItem = selectedItem;
            }
        }
    }
    get parsedQuery() {
        if (!this.searchQuery)
            return null;
        return this.searchQuery;
    }
    onSearchQueryChange(val, oldVal) {
        if (this.searchTimeoutID > 0) {
            window.clearTimeout(this.searchTimeoutID);
        }
        this.searchTimeoutID = window.setTimeout(() => {
            this.searchQuery = this.searchInput || '';
        }, 500);
    }
    async endIntersect(entries, observer, isIntersecting) {
        if (isIntersecting && !this.isLoadingChoiceTypes && this.templateChoiceTypeCollection.pageInfo.hasNextPage) {
            await this.fetchMore();
        }
    }
    fetchMore() {
        this.$apollo.queries.templateChoiceTypeCollection.fetchMore({
            variables: {
                skip: this.templateChoiceTypeCollection.items.length
            }, updateQuery: (previousResult, { fetchMoreResult }) => {
                fetchMoreResult.templateCollection.items = [...previousResult.templateChoiceTypeCollection.items, ...fetchMoreResult.templateChoiceTypeCollection.items];
                return fetchMoreResult;
            }
        });
    }
};
__decorate([
    Prop({ type: Number, required: true, default: null })
], ChoiceTypeAutoComplete.prototype, "selectedId", void 0);
__decorate([
    Watch('templateChoiceTypeCollection', { deep: true })
], ChoiceTypeAutoComplete.prototype, "onChoiceListChanged", null);
__decorate([
    Watch('searchInput')
], ChoiceTypeAutoComplete.prototype, "onSearchQueryChange", null);
ChoiceTypeAutoComplete = __decorate([
    Component({
        components: { MoveButton, DeleteButton },
        apollo: {
            templateChoiceTypeCollection: {
                query: gql `query templateChoiceTypeCollection($skip: Int!, $take: Int!, $query: String){
        templateChoiceTypeCollection: templateChoiceTypes(skip: $skip, take: $take
          where: {
            or: [ { description: { contains: $query } }]
          }) {
            items {
              id
              description
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            totalCount
          }
        }`,
                fetchPolicy: "no-cache",
                variables() {
                    return {
                        skip: 0,
                        take: localStorage.getItem(PAGESIZELOCALSTORAGEKEY) || PAGESIZE,
                        query: this.parsedQuery,
                    };
                },
                loadingKey: 'isLoadingChoiceTypes'
            }
        }
    })
], ChoiceTypeAutoComplete);
export default ChoiceTypeAutoComplete;
