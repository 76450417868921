import { __decorate } from "tslib";
import AppBarResponsiveButton from "@/components/AppBarResponsiveButton.vue";
import DefaultMenu from "@/components/DefaultMenu.vue";
import TemplateEditView from "@/components/Templates/TemplateEditView.vue";
import TemplateList from "@/components/Templates/TemplateList.vue";
import { TemplateCollectionSegment } from "@/types/Api/Template/TemplateCollectionSegments";
import gql from 'graphql-tag';
import { Component, Prop, Vue } from "vue-property-decorator";
import TemplateAddDialog from "@/components/Templates/TemplateAddDialog.vue";
import { trackAction } from "@/store/Helpers/UserMonitor";
const PAGESIZE = 20;
const PAGESIZELOCALSTORAGEKEY = 'pagesize';
let Templates = class Templates extends Vue {
    templateCollection = new TemplateCollectionSegment();
    isLoading = 0;
    searchQuery;
    showTemplateAddDialog = false;
    get query() {
        if (!this.searchQuery)
            return null;
        return this.searchQuery;
    }
    addTemplate() {
        this.showTemplateAddDialog = true;
        trackAction("Show template add dialog");
    }
    async deleteTemplate(template) {
        this.$promiseQueue.enqueue(() => {
            return new Promise((resolve, reject) => {
                this.$apollo.mutate({
                    mutation: gql `mutation ($input: DeleteTemplateInput!) {
              resultData: deleteTemplate(input: $input) {
                template {
                  key            
                }
              }
            }`,
                    variables: {
                        input: { templateKey: template.key }
                    }
                }).then(result => {
                    //todo: Remove item from cache?
                    this.$apollo.queries.templateCollection.refetch({
                        skip: 0,
                        query: this.query,
                        take: localStorage.getItem(PAGESIZELOCALSTORAGEKEY) || PAGESIZE
                    });
                    resolve(template);
                }).catch(error => {
                    reject(error);
                });
            });
        }, { dataObject: template, description: this.$t('deleting-template').toString() }, true);
        trackAction("Delete template");
    }
    fetchMore() {
        this.$apollo.queries.templateCollection.fetchMore({
            variables: {
                skip: this.templateCollection.items.length
            }, updateQuery: (previousResult, { fetchMoreResult }) => {
                fetchMoreResult.templateCollection.items = [...previousResult.templateCollection.items, ...fetchMoreResult.templateCollection.items];
                return fetchMoreResult;
            }
        });
    }
};
__decorate([
    Prop({ type: String, required: false, default: "" })
], Templates.prototype, "searchQuery", void 0);
Templates = __decorate([
    Component({
        components: {
            TemplateEditView,
            AppBarResponsiveButton,
            TemplateList,
            DefaultMenu,
            TemplateAddDialog
        },
        apollo: {
            templateCollection: {
                loadingKey: "isLoading",
                query: gql `query templateCollection($skip: Int!, $take: Int!, $query: String) {
                  templateCollection: templates(skip: $skip, take: $take
                   where: {
                     or: [{ key: { contains: $query } }]
                   }) {
                    items {
                      id
                      description
                      key
                    }
                    pageInfo {
                      hasNextPage
                      hasPreviousPage
                    }
                    totalCount
                  }
                }`,
                variables() {
                    return {
                        skip: 0,
                        query: this.query,
                        take: localStorage.getItem(PAGESIZELOCALSTORAGEKEY) || PAGESIZE
                    };
                },
                fetchPolicy: 'cache-and-network'
            }
        }
    })
], Templates);
export default Templates;
