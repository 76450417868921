export class TemplateChoiceTypeUpdate {
    id = 0;
    description = '';
    constructor(templateChoiceType) {
        if (templateChoiceType) {
            this.id = templateChoiceType.id;
            this.description = templateChoiceType.description;
        }
    }
}
