import { TemplateBooleanInspectionUpdate } from "./TemplateBooleanInspectionUpdate";
import { TemplateNumericalInspectionUpdate } from "./TemplateNumericalInspectionUpdate";
import { TemplateImageInspectionUpdate } from "./TemplateImageInspectionUpdate";
import { TemplateSingleLineInspectionUpdate } from "./TemplateSingleLineInspectionUpdate";
import { TemplateMultiLineInspectionUpdate } from "./TemplateMultiLineInspectionUpdate";
import { TemplateChoiceInspectionUpdate } from "./TemplateChoiceInspectionUpdate";
import { TemplateDateInspectionUpdate } from "./TemplateDateInspectionUpdate";
export class TemplateInspectionUpdate {
    id = 0;
    inspectionGroupId = 0;
    sortOrder = 0;
    booleanInspection;
    numericalInspection;
    imageInspection;
    singleLineInspection;
    multiLineInspection;
    choiceInspection;
    dateInspection;
    constructor(templateInspection) {
        if (templateInspection) {
            this.id = templateInspection.id;
            this.inspectionGroupId = templateInspection.inspectionGroupId;
            this.sortOrder = templateInspection.sortOrder;
            if (templateInspection.booleanInspection) {
                this.booleanInspection = new TemplateBooleanInspectionUpdate(templateInspection.booleanInspection);
            }
            else if (templateInspection.numericalInspection) {
                this.numericalInspection = new TemplateNumericalInspectionUpdate(templateInspection.numericalInspection);
            }
            else if (templateInspection.imageInspection) {
                this.imageInspection = new TemplateImageInspectionUpdate(templateInspection.imageInspection);
            }
            else if (templateInspection.singleLineInspection) {
                this.singleLineInspection = new TemplateSingleLineInspectionUpdate(templateInspection.singleLineInspection);
            }
            else if (templateInspection.multiLineInspection) {
                this.multiLineInspection = new TemplateMultiLineInspectionUpdate(templateInspection.multiLineInspection);
            }
            else if (templateInspection.choiceInspection) {
                this.choiceInspection = new TemplateChoiceInspectionUpdate(templateInspection.choiceInspection);
            }
            else if (templateInspection.dateInspection) {
                this.dateInspection = new TemplateDateInspectionUpdate(templateInspection.dateInspection);
            }
            else {
                console.error("No mandatory boolean/numerical/image/single/multiline/choice/date-inspection inspection object.");
            }
        }
    }
}
