import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
let SubmitIndicator = class SubmitIndicator extends Vue {
    submitted;
    get submitText() {
        if (this.submitted) {
            return this.$t('submit-state-submitted').toString();
        }
        else {
            return this.$t('submit-state-not-submitted').toString();
        }
    }
};
__decorate([
    Prop({ type: Boolean, required: true, default: false })
], SubmitIndicator.prototype, "submitted", void 0);
SubmitIndicator = __decorate([
    Component({
        components: {},
    })
], SubmitIndicator);
export default SubmitIndicator;
