export class InspectionListSingleLineInspectionUpdate {
    id = 0;
    value = undefined;
    constructor(singleLineInspection) {
        if (singleLineInspection) {
            this.id = singleLineInspection.id;
            this.value = singleLineInspection.value;
        }
    }
}
