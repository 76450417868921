import { TemplateBooleanInspectionInput } from "./TemplateBooleanInspectionInput";
import { TemplateNumericalInspectionInput } from "./TemplateNumericalInspectionInput";
import { TemplateImageInspectionInput } from "./TemplateImageInspectionInput";
import { TemplateSingleLineInspectionInput } from "./TemplateSingleLineInspectionInput";
import { TemplateMultiLineInspectionInput } from "./TemplateMultiLineInspectionInput";
import { TemplateChoiceInspectionInput } from "./TemplateChoiceInspectionInput";
import { TemplateDateInspectionInput } from "./TemplateDateInspectionInput";
export class TemplateInspectionInput {
    sortOrder = 0;
    inspectionGroupId = 0;
    booleanInspection;
    numericalInspection;
    imageInspection;
    singleLineInspection;
    multiLineInspection;
    choiceInspection;
    dateInspection;
    constructor(templateInspection) {
        if (templateInspection) {
            this.sortOrder = templateInspection.sortOrder;
            this.inspectionGroupId = templateInspection.inspectionGroupId;
            if (templateInspection.booleanInspection) {
                this.booleanInspection = new TemplateBooleanInspectionInput(templateInspection.booleanInspection);
            }
            else if (templateInspection.numericalInspection) {
                this.numericalInspection = new TemplateNumericalInspectionInput(templateInspection.numericalInspection);
            }
            else if (templateInspection.imageInspection) {
                this.imageInspection = new TemplateImageInspectionInput(templateInspection.imageInspection);
            }
            else if (templateInspection.singleLineInspection) {
                this.singleLineInspection = new TemplateSingleLineInspectionInput(templateInspection.singleLineInspection);
            }
            else if (templateInspection.multiLineInspection) {
                this.multiLineInspection = new TemplateMultiLineInspectionInput(templateInspection.multiLineInspection);
            }
            else if (templateInspection.choiceInspection) {
                this.choiceInspection = new TemplateChoiceInspectionInput(templateInspection.choiceInspection);
            }
            else if (templateInspection.dateInspection) {
                this.dateInspection = new TemplateDateInspectionInput(templateInspection.dateInspection);
            }
            else {
                console.error("No mandatory boolean/numerical/image/single/multiline/choice/date-inspection inspection object.");
            }
        }
    }
}
