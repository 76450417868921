import Vue from 'vue';
import VueRouter from 'vue-router';
import TemplateEdit from '../views/TemplateEdit.vue';
import Home from '../views/Home.vue';
import InspectionSearchEdit from '../views/InspectionSearchEdit.vue';
import Templates from '../views/Templates.vue';
import InspectionList from '../views/InspectionList.vue';
import idsrvAuth from '../plugins/vue-oidc-client/idsrvAuth';
import Inspections from '../views/Inspections.vue';
import mainOidc from '@/plugins/vue-oidc-client/idsrvAuth';
import Unauthenticated from '@/views/Unauthenticated.vue';
import { datadogRum } from '@datadog/browser-rum';
import TemplateChoiceTypesView from '../views/ChoiceTypes.vue';
import TemplateChoiceTypeEdit from '../views/ChoiceTypeEdit.vue';
Vue.use(VueRouter);
const routes = [
    {
        path: '/',
        name: 'root',
        component: Home,
        meta: {
            authName: idsrvAuth.authName,
            roles: ['Inspections', 'InspectionsEngineer']
        }
    },
    {
        path: '/choicetypes',
        name: 'TemplateChoiceTypes',
        component: TemplateChoiceTypesView,
        meta: {
            authName: idsrvAuth.authName,
            showInMenu: true,
            menuIcon: 'dropdown',
            menuOrder: 30,
            menuTranslation: 'menu_choicetypes',
            showSearch: true,
            roles: ['InspectionsEngineer'],
            helpSection: 'home/keuzelijsten'
        }
    },
    {
        path: '/choicetypes/edit/:id',
        name: 'TemplateChoiceTypeEdit',
        component: TemplateChoiceTypeEdit,
        meta: {
            authName: idsrvAuth.authName,
            showInMenu: false,
            roles: ['InspectionsEngineer'],
            alwaysShowSaveButton: true,
            helpSection: 'home/14226'
        }
    },
    {
        path: '/templates',
        name: 'Templates',
        component: Templates,
        meta: {
            authName: idsrvAuth.authName,
            showInMenu: true,
            menuIcon: 'clipboard',
            menuOrder: 10,
            menuTranslation: 'menu_templates',
            showSearch: true,
            roles: ['InspectionsEngineer'],
            helpSection: 'home/templates'
        }
    },
    {
        path: '/templates/edit/:key',
        name: 'TemplateEdit',
        component: TemplateEdit,
        meta: {
            authName: idsrvAuth.authName,
            showInMenu: false,
            roles: ['InspectionsEngineer'],
            alwaysShowSaveButton: true,
            helpSection: 'home/13965'
        }
    },
    {
        path: '/inspections/list',
        name: 'InspectionsList',
        component: InspectionList,
        meta: {
            authName: idsrvAuth.authName,
            showInMenu: true,
            menuIcon: 'clipboard5',
            menuOrder: 20,
            menuTranslation: 'menu_inspections',
            showSearch: true,
            roles: ['InspectionsEngineer'],
            helpSection: 'home/inspectielijsten'
        }
    },
    {
        path: '/inspections',
        name: 'Inspections',
        component: Inspections,
        meta: {
            authName: idsrvAuth.authName,
            showInMenu: false,
            hideDrawer: true,
            roles: ['Inspections'],
            helpSection: 'home/13986'
        }
    },
    {
        path: '/403',
        name: '403',
        component: Unauthenticated,
        meta: {
            showInMenu: false,
            hideDrawer: true
        }
    },
    {
        path: '/inspections/:key',
        name: 'InspectionSearchEdit',
        component: InspectionSearchEdit,
        meta: {
            authName: idsrvAuth.authName,
            showInMenu: false,
            hideDrawer: true,
            from: 'list',
            roles: ['Inspections', 'InspectionsEngineer'],
            alwaysShowSaveButton: true,
            helpSection: 'home/13937'
        }
    }
];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior: (to, from, savedPosition) => {
        if (savedPosition) {
            return savedPosition;
        }
        else {
            return { x: 0, y: 0 };
        }
    }
});
router.beforeEach((to, from, next) => {
    datadogRum.startView(to.name ?? to.path);
    //Let the system handle authentication by next
    if (!mainOidc.isAuthenticated) {
        next();
        return;
    }
    if (new RegExp(".*/?signout$").test(to.path)) {
        mainOidc.signOut();
        return;
    }
    //Check if the current user has permissions for this role.
    const rolesForPage = to.meta?.roles;
    const currentUserRoles = mainOidc.userProfile?.roles;
    if (!rolesForPage) {
        //Page has no roles-limitation, so continue    
        next();
    }
    else if (!currentUserRoles) {
        //Current user has no roles in his profile, redirect to error page
        next({ name: "403" });
    }
    // else if (to.name == 'root')
    // {
    //    //Redirect is moved to home view. It gives errors with the oidc redirects if done from here.
    // }
    else if (rolesForPage.some(r => currentUserRoles.indexOf(r) >= 0)) {
        //The user has the required roles for this page, continue
        next();
    }
    else {
        //The user does not have the required roles, redirect to error page
        next({ name: "403" });
    }
});
idsrvAuth.useRouter(router);
export default router;
