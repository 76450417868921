import { __decorate } from "tslib";
import gql from 'graphql-tag';
import { Component, ModelSync, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import copyLinkButton from '../CopyLinkButton.vue';
import { trackAction } from "@/store/Helpers/UserMonitor";
import { Mutation } from 'vuex-class';
import ErrorBar from '../ErrorBar.vue';
let TemplateCopyDialog = class TemplateCopyDialog extends Vue {
    showDialogValue;
    templateKey;
    setHideDefaultErrorBar;
    clearErrors;
    isLoading = false;
    identifier = '';
    description = '';
    async mounted() {
        this.identifier = '';
    }
    onVisibleChanged(value) {
        if (!value) {
            this.identifier = '';
            this.description = '';
            this.setHideDefaultErrorBar(false);
            this.clearErrors();
        }
        else {
            this.setHideDefaultErrorBar(true);
        }
    }
    identifierTextField;
    hide() {
        this.showDialogValue = false;
    }
    get canSubmit() {
        if (this.identifier && this.description && !this.isLoading) {
            return true;
        }
        return false;
    }
    save() {
        if (!this.canSubmit) {
            return;
        }
        this.isLoading = true;
        this.$apollo.mutate({
            mutation: gql `mutation ($input: CopyTemplateInput!) {
        copiedTemplate: copyTemplate(input: $input) {
          template {
            key
          }
        }
      }`,
            variables: {
                input: { sourceTemplateKey: this.templateKey, targetTemplateKey: this.identifier, targetTemplateDescription: this.description }
            },
        }).then((result) => {
            this.$router.push({ name: "TemplateEdit", params: { key: result.data.copiedTemplate.template.key } });
            this.identifier = '';
            this.description = '';
            this.showDialogValue = false;
            this.isLoading = false;
            trackAction("Template copied");
        })
            .catch((error) => {
            this.isLoading = false;
        });
    }
};
__decorate([
    ModelSync('showDialog', 'visiblityChanged', { type: Boolean, required: true })
], TemplateCopyDialog.prototype, "showDialogValue", void 0);
__decorate([
    Prop({ type: String, required: true })
], TemplateCopyDialog.prototype, "templateKey", void 0);
__decorate([
    Mutation
], TemplateCopyDialog.prototype, "setHideDefaultErrorBar", void 0);
__decorate([
    Mutation
], TemplateCopyDialog.prototype, "clearErrors", void 0);
__decorate([
    Watch('showDialogValue')
], TemplateCopyDialog.prototype, "onVisibleChanged", null);
__decorate([
    Ref('identifierTextField')
], TemplateCopyDialog.prototype, "identifierTextField", void 0);
TemplateCopyDialog = __decorate([
    Component({
        components: {
            copyLinkButton,
            ErrorBar
        },
    })
], TemplateCopyDialog);
export default TemplateCopyDialog;
