import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
let MultiLineTextInspectionComponent = class MultiLineTextInspectionComponent extends Vue {
    value;
    onChange() {
        return this.value;
    }
};
__decorate([
    Prop({ type: Object, required: false, default: null })
], MultiLineTextInspectionComponent.prototype, "value", void 0);
__decorate([
    Emit('change')
], MultiLineTextInspectionComponent.prototype, "onChange", null);
MultiLineTextInspectionComponent = __decorate([
    Component({
        components: {},
    })
], MultiLineTextInspectionComponent);
export default MultiLineTextInspectionComponent;
