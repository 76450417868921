import { __decorate } from "tslib";
import { Component, Vue, Prop, Watch, ModelSync } from 'vue-property-decorator';
let TrivestDialog = class TrivestDialog extends Vue {
    title;
    icon;
    color;
    submitText;
    cancelable;
    canSubmit;
    loading;
    showButtons;
    closeOnSubmit;
    height;
    dialog;
    onDialogChange(val, oldVal) {
        if (val) {
            this.$emit('show');
        }
        else {
            this.$emit('hide');
        }
        this.$emit('input', val);
    }
    onCancel() {
        this.$emit('cancel');
        this.dialog = false;
    }
    onSubmit() {
        this.$emit('submit');
        if (this.closeOnSubmit)
            this.dialog = false;
    }
    mounted() {
        this.$root.$on('show-dialog', () => {
            this.dialog = true;
        });
        this.$on('hook:beforeDestroy', () => {
            this.$root.$off('show-dialog');
        });
    }
};
__decorate([
    Prop({ type: String, default: '' })
], TrivestDialog.prototype, "title", void 0);
__decorate([
    Prop({ type: String, default: '' })
], TrivestDialog.prototype, "icon", void 0);
__decorate([
    Prop({ type: String, default: 'accent' })
], TrivestDialog.prototype, "color", void 0);
__decorate([
    Prop({ type: String })
], TrivestDialog.prototype, "submitText", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], TrivestDialog.prototype, "cancelable", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], TrivestDialog.prototype, "canSubmit", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], TrivestDialog.prototype, "loading", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], TrivestDialog.prototype, "showButtons", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], TrivestDialog.prototype, "closeOnSubmit", void 0);
__decorate([
    Prop({ type: String, default: '85vh' })
], TrivestDialog.prototype, "height", void 0);
__decorate([
    ModelSync('showDialog', 'visibilityChanged', { type: Boolean, default: false })
], TrivestDialog.prototype, "dialog", void 0);
__decorate([
    Watch('dialog')
], TrivestDialog.prototype, "onDialogChange", null);
TrivestDialog = __decorate([
    Component({
        components: {}
    })
], TrivestDialog);
export default TrivestDialog;
