import VueI18n from 'vue-i18n';
const dateTimeFormats = {
    en: {
        weekday: {
            weekday: 'long',
        },
        dateOnly: {
            day: 'numeric',
            month: 'long'
        },
        dateShort: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        },
        dateLong: {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        },
        dateTimeShort: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        },
        timeShort: {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        }
    },
    nl: {
        weekday: {
            weekday: 'long',
        },
        dateOnly: {
            day: 'numeric',
            month: 'long'
        },
        dateShort: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric'
        },
        dateLong: {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        },
        dateTimeShort: {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        },
        timeShort: {
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        }
    }
};
function loadLocaleMessages() {
    const locales = require.context('@/translations', true, /[A-Za-z0-9-_,\s]+\.json$/i);
    const messages = {};
    locales.keys().forEach(key => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
            const locale = matched[1];
            messages[locale] = locales(key);
        }
    });
    return messages;
}
export function addI18nPlugin(Vue) {
    Vue.use(VueI18n);
}
let i18n;
export function setI18n(newI18n) {
    i18n = newI18n;
}
export const getLanguageLocale = (locale) => locale.substr(0, 2);
export const hasMessagesForLocale = (locale) => !!i18n.messages[locale];
export const getAvailableI18nLocale = (currentLocale = i18n.locale) => {
    if (!hasMessagesForLocale(currentLocale)) {
        const languageLocale = getLanguageLocale(currentLocale);
        if (!hasMessagesForLocale(languageLocale)) {
            return process.env.VUE_APP_I18N_LOCALE || 'en';
        }
        return languageLocale;
    }
    return currentLocale;
};
export default function () {
    if (!i18n) {
        i18n = new VueI18n({
            dateTimeFormats,
            silentFallbackWarn: true,
            locale: navigator.language || process.env.VUE_APP_I18N_LOCALE || 'en-GB',
            messages: loadLocaleMessages(),
            missing: (locale) => {
                i18n.locale = getAvailableI18nLocale(locale);
                return (i18n.getLocaleMessage(i18n.locale) ||
                    i18n.getLocaleMessage(process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en'));
            }
        });
    }
    return i18n;
}
