import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import TextHighlight from 'vue-text-highlight';
let InspectionListHighLighter = class InspectionListHighLighter extends Vue {
    backgroundcolor;
    color;
    search;
    get queries() {
        return this.search;
    }
    get styleObject() {
        return {
            color: this.color,
            'background-color': this.backgroundcolor
        };
    }
};
__decorate([
    Prop({ type: String, default: '#F6C99E' })
], InspectionListHighLighter.prototype, "backgroundcolor", void 0);
__decorate([
    Prop({ type: String, default: 'inherit' })
], InspectionListHighLighter.prototype, "color", void 0);
__decorate([
    Prop({ type: String, default: '' })
], InspectionListHighLighter.prototype, "search", void 0);
InspectionListHighLighter = __decorate([
    Component({
        components: {
            TextHighlight
        }
    })
], InspectionListHighLighter);
export default InspectionListHighLighter;
