import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
import copy from "clipboard-copy";
import { trackAction } from "@/store/Helpers/UserMonitor";
let copyLinkButton = class copyLinkButton extends Vue {
    route;
    text;
    small;
    showBackgroundCircle;
    elevateOnHover;
    copiedUrl = false;
    onCopyLink() {
        if (this.route) {
            const url = this.$router.resolve(this.route);
            var host = location.protocol.concat("//").concat(window.location.host);
            copy(host.concat(url.href));
        }
        else {
            copy(this.text);
        }
        this.copiedUrl = true;
        trackAction("Copy link");
    }
};
__decorate([
    Prop({ type: Object, required: false })
], copyLinkButton.prototype, "route", void 0);
__decorate([
    Prop({ type: String, required: false, default: '' })
], copyLinkButton.prototype, "text", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], copyLinkButton.prototype, "small", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], copyLinkButton.prototype, "showBackgroundCircle", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], copyLinkButton.prototype, "elevateOnHover", void 0);
copyLinkButton = __decorate([
    Component({
        components: {},
    })
], copyLinkButton);
export default copyLinkButton;
