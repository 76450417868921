import { __decorate } from "tslib";
import { generateApiUrl } from '@/Helpers/BaseUrl';
import axios from 'axios';
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import DeleteButton from "../DeleteButton.vue";
import mainOidc from '@/plugins/vue-oidc-client/idsrvAuth';
import gql from 'graphql-tag';
import { trackAction } from '@/store/Helpers/UserMonitor';
let InspectionListAttachmentsThumbnail = class InspectionListAttachmentsThumbnail extends Vue {
    filename;
    inspectionListKey;
    readonly;
    objectUrl = '';
    loadingError = false;
    beforeUnmount() {
        URL.revokeObjectURL(this.objectUrl);
    }
    async mounted() {
        if (this.filename && this.inspectionListKey) {
            await this.getImage(this.filename);
        }
    }
    openAttachment() {
        trackAction("Open attachment");
        return this.filename;
    }
    async onFileChange(newFile) {
        URL.revokeObjectURL(this.objectUrl);
        await this.getImage(newFile);
    }
    onInspectionChange() {
        URL.revokeObjectURL(this.objectUrl);
    }
    async getImage(filename) {
        try {
            const response = await axios
                .get(`${generateApiUrl('inspections/attachments')}/${encodeURIComponent(this.inspectionListKey)}/${filename}?thumb=true`, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': mainOidc.isAuthenticated ? `Bearer ${mainOidc.accessToken}` : ''
                }
            });
            const blob = new Blob([response.data]);
            this.objectUrl = URL.createObjectURL(blob);
        }
        catch (error) {
            console.error("Error loading image");
            console.error(error);
        }
        return;
    }
    get supportTouch() {
        return ('ontouchstart' in window);
    }
    async deleteAttachment() {
        trackAction("Delete attachment");
        this.$promiseQueue.enqueue(() => {
            return new Promise((resolve, reject) => {
                let result = this.$apollo.mutate({
                    mutation: gql `mutation ($input: DeleteInspectionListImageInput!) {
            updatedInspectionList: deleteInspectionListImage(input: $input) {
              inspectionList {
                id
                attachments
              }
            }
          }`,
                    variables: {
                        input: {
                            inspectionListKey: this.inspectionListKey,
                            fileName: this.filename
                        }
                    },
                }).then(result => {
                    resolve(this.filename);
                }).catch(error => {
                    reject(error);
                });
            });
        }, {
            dataObject: this.filename,
            description: this.$t('delete-attachments').toString(),
        }, true);
    }
};
__decorate([
    Prop({ type: String, required: true })
], InspectionListAttachmentsThumbnail.prototype, "filename", void 0);
__decorate([
    Prop({ type: String, required: true })
], InspectionListAttachmentsThumbnail.prototype, "inspectionListKey", void 0);
__decorate([
    Prop({ type: Boolean, required: true })
], InspectionListAttachmentsThumbnail.prototype, "readonly", void 0);
__decorate([
    Emit('openAttachment')
], InspectionListAttachmentsThumbnail.prototype, "openAttachment", null);
__decorate([
    Watch('filename')
], InspectionListAttachmentsThumbnail.prototype, "onFileChange", null);
__decorate([
    Watch('inspectionListKey')
], InspectionListAttachmentsThumbnail.prototype, "onInspectionChange", null);
InspectionListAttachmentsThumbnail = __decorate([
    Component({
        components: {
            DeleteButton
        }
    })
], InspectionListAttachmentsThumbnail);
export default InspectionListAttachmentsThumbnail;
