import { __decorate } from "tslib";
import { generateApiUrl } from '@/Helpers/BaseUrl';
import { clearErrors, handleCommonError } from '@/Helpers/ErrorHandlers';
import mainOidc from '@/plugins/vue-oidc-client/idsrvAuth';
import { TemplateInspectionUpdate } from "@/types/Api/Template/Update/TemplateInspectionUpdate";
import axios from 'axios';
import gql from "graphql-tag";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import DeleteButton from "../../DeleteButton.vue";
import MoveButton from "../../MoveButton.vue";
let ImageInspectionEdit = class ImageInspectionEdit extends Vue {
    value;
    hover;
    templateKey;
    editEnabled = false;
    file;
    blobUrl = '';
    loadingImage = false;
    async mounted() {
        this.getFile();
    }
    hideImage() {
        this.blobUrl = '';
    }
    get showImage() {
        return !!this.effectiveUrl || !!this.value.imageInspection?.fileName;
    }
    get effectiveUrl() {
        if (this.value.imageInspection && this.value.imageInspection.tempUrl) {
            return this.value.imageInspection.tempUrl;
        }
        else {
            return this.blobUrl;
        }
    }
    setEditMode(value) {
        if (!value && this.editEnabled) {
            this.doSave();
        }
        this.editEnabled = value;
    }
    doSave() {
        this.$promiseQueue.enqueue(() => {
            let updateObject = new TemplateInspectionUpdate(this.value);
            return new Promise((resolve, reject) => {
                this.$apollo.mutate({
                    mutation: gql `mutation ($input: UpdateTemplateInspectionInput!) 
          {
            updatedInspection: updateTemplateInspection(input: $input) {
              templateInspection {
                id
                sortOrder
              }
            }
          }`,
                    variables: {
                        input: { templateInspection: updateObject }
                    },
                    fetchPolicy: "no-cache"
                }).then(_result => {
                    resolve(this.value);
                }).catch(error => {
                    reject(error);
                });
            });
        }, {
            dataObject: this.value,
            description: this.$t('update-inspection-edit-description', [this.getDescription()]).toString(),
        }, true);
    }
    getDescription() {
        if (this.value.imageInspection?.description)
            return this.value.imageInspection.description;
        else
            return this.$t('inspectionTitle');
    }
    onKeyDown(event) {
        if (["Enter", "Esc", "Escape"].indexOf(event.key) > -1) {
            this.closeEditMode();
        }
    }
    closeEditMode() {
        this.setEditMode(false);
    }
    showEditMode() {
        this.setEditMode(true);
    }
    get supportTouch() {
        return ('ontouchstart' in window);
    }
    onDelete() {
        if (this.value.imageInspection && this.value.imageInspection.tempUrl) {
            URL.revokeObjectURL(this.value.imageInspection.tempUrl);
            this.value.imageInspection.tempUrl = '';
        }
        if (this.blobUrl) {
            URL.revokeObjectURL(this.blobUrl);
            this.blobUrl = '';
        }
        return true;
    }
    async handleFileChange(e) {
        clearErrors();
        this.file = e.target.files[0];
        // check if file size is <= 3Mb
        if (this.file.size > 3145728) {
            handleCommonError(this.$t('max-file-size').toString());
            this.file = null;
            return;
        }
        if (e.target.validity.valid) {
            if (this.value.imageInspection == null)
                return;
            this.setEditMode(true);
            this.blobUrl = URL.createObjectURL(this.file);
            this.value.imageInspection.tempUrl = URL.createObjectURL(this.file);
            try {
                this.submitFile(`${generateApiUrl('templates/inspections/image/upload')}/${encodeURIComponent(this.value.id)}`);
                this.getFile();
            }
            finally {
                URL.revokeObjectURL(this.blobUrl);
                this.setEditMode(false);
            }
        }
    }
    submitFile(uploadUrl) {
        let formData = new FormData();
        formData.append('file', this.file);
        this.loadingImage = true;
        axios
            .post(uploadUrl, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': mainOidc.isAuthenticated ? `Bearer ${mainOidc.accessToken}` : ''
            }
        })
            .then(() => {
            this.loadingImage = false;
        })
            .catch((err) => {
            this.loadingImage = false;
            console.error(err);
        });
    }
    getFile() {
        if (!this.value.imageInspection?.fileName) {
            return;
        }
        this.loadingImage = true;
        const downloadUrl = `${generateApiUrl('templates')}/${encodeURIComponent(this.templateKey)}/inspections/image/${encodeURIComponent(this.value.imageInspection.fileName)}`;
        if (downloadUrl) {
            let headers = new Headers();
            headers.append('Authorization', mainOidc.isAuthenticated ? `Bearer ${mainOidc.accessToken}` : '');
            fetch(downloadUrl, { headers })
                .then(response => response.blob())
                .then((blobby) => {
                this.blobUrl = URL.createObjectURL(blobby);
                this.loadingImage = false;
            })
                .catch((err) => {
                console.error(err);
                this.blobUrl = '';
                this.loadingImage = false;
            });
        }
        else {
            this.blobUrl = '';
        }
    }
};
__decorate([
    Prop({ type: Object, required: true, default: null })
], ImageInspectionEdit.prototype, "value", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], ImageInspectionEdit.prototype, "hover", void 0);
__decorate([
    Prop({ type: String, required: true })
], ImageInspectionEdit.prototype, "templateKey", void 0);
__decorate([
    Emit('delete')
], ImageInspectionEdit.prototype, "onDelete", null);
ImageInspectionEdit = __decorate([
    Component({
        components: { MoveButton, DeleteButton },
    })
], ImageInspectionEdit);
export default ImageInspectionEdit;
