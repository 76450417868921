import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
let CustomCheckbox = class CustomCheckbox extends Vue {
    value;
    disabled;
    toggleSelection() {
        if (this.disabled)
            return;
        else
            this.$emit('onSelectionChanged', this);
    }
};
__decorate([
    Prop({ type: Boolean, default: false })
], CustomCheckbox.prototype, "value", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], CustomCheckbox.prototype, "disabled", void 0);
CustomCheckbox = __decorate([
    Component({
        components: {},
    })
], CustomCheckbox);
export default CustomCheckbox;
