export class InspectionListNumericalInspectionUpdate {
    id = 0;
    measurement;
    constructor(numericalInspection) {
        if (numericalInspection) {
            this.id = numericalInspection.id;
            this.measurement = numericalInspection.measurement;
        }
    }
}
