import { __decorate } from "tslib";
import { Component, Watch, Emit, Prop, Vue } from "vue-property-decorator";
let DateInspectionComponent = class DateInspectionComponent extends Vue {
    value;
    dateSelector = false;
    onDateChanged() {
        this.$emit('change', this.value);
    }
    set computedLocalDate(value) {
        if (this.value?.dateInspection) {
            this.value.dateInspection.date = value;
        }
    }
    get computedLocalDate() {
        if (this.value?.dateInspection?.date) {
            var date = new Date(this.value.dateInspection.date);
            if (this.$vuetify.breakpoint.mdAndUp) {
                return this.$d(date, 'dateLong');
            }
            else {
                return this.$d(date, 'dateShort');
            }
        }
        return this.$i18n.t('date-selection').toString();
    }
    onChange() {
        return this.value;
    }
};
__decorate([
    Prop({ type: Object, required: false, default: null })
], DateInspectionComponent.prototype, "value", void 0);
__decorate([
    Watch('value.dateInspection.date', { deep: true })
], DateInspectionComponent.prototype, "onDateChanged", null);
__decorate([
    Emit('change')
], DateInspectionComponent.prototype, "onChange", null);
DateInspectionComponent = __decorate([
    Component({
        components: {},
    })
], DateInspectionComponent);
export default DateInspectionComponent;
