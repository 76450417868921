import { __decorate } from "tslib";
import CopyLinkButton from "@/components/CopyLinkButton.vue";
import UndoSubmitButton from "@/components/UndoSubmitButton.vue";
import { trackAction } from "@/store/Helpers/UserMonitor";
import gql from 'graphql-tag';
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import CustomCheckbox from "../CustomCheckbox.vue";
let InspectionListListItem = class InspectionListListItem extends Vue {
    inspectionList;
    isSelected;
    toggleSelection() {
        return this.inspectionList;
    }
    openInspectionList(inspectionList) {
        this.$router.push(this.getInspectionListRoute(inspectionList));
    }
    getInspectionListRoute(inspectionList) {
        return { name: "InspectionSearchEdit", params: { key: inspectionList.key } };
    }
    async onClearSubmitState(inspectionList) {
        try {
            trackAction("Revert submit inspection");
            //Only request updated id's and submit state. This will prevent overwriting any other pending user changes
            await this.$apollo.mutate({
                mutation: gql `mutation ($input: ClearInspectionListSubmitStateInput!) {
            updatedInspectionList: clearInspectionListSubmitState(input: $input) {
              inspectionList {
                id
                submitted
              }
            }
          }`,
                variables: {
                    input: { inspectionListKey: inspectionList.key }
                }
            });
            if (this.isSelected) {
                this.toggleSelection();
            }
        }
        catch (error) {
            console.log("Mutation clear submit state error");
        }
    }
};
__decorate([
    Prop({ type: Object, default: null })
], InspectionListListItem.prototype, "inspectionList", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], InspectionListListItem.prototype, "isSelected", void 0);
__decorate([
    Emit('onSelectionChanged')
], InspectionListListItem.prototype, "toggleSelection", null);
InspectionListListItem = __decorate([
    Component({
        components: {
            CopyLinkButton,
            UndoSubmitButton,
            CustomCheckbox
        },
    })
], InspectionListListItem);
export default InspectionListListItem;
