import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import SkeletonEditField from "@/components/SkeletonEditField.vue";
let InspectionListEditSkeleton = class InspectionListEditSkeleton extends Vue {
};
InspectionListEditSkeleton = __decorate([
    Component({
        components: {
            SkeletonEditField
        },
    })
], InspectionListEditSkeleton);
export default InspectionListEditSkeleton;
