import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import SkeletonEditField from "@/components/SkeletonEditField.vue";
let ChoiceTypeEditSkeleton = class ChoiceTypeEditSkeleton extends Vue {
};
ChoiceTypeEditSkeleton = __decorate([
    Component({
        components: {
            SkeletonEditField
        },
    })
], ChoiceTypeEditSkeleton);
export default ChoiceTypeEditSkeleton;
