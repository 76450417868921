import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
let MoveButton = class MoveButton extends Vue {
};
MoveButton = __decorate([
    Component({
        components: {},
    })
], MoveButton);
export default MoveButton;
