import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import { datadogRum } from '@datadog/browser-rum';
Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
    key: 'InspectionsApp',
    storage: sessionStorage,
    reducer: (state) => ({
        languageOverride: state.languageOverride
    }),
    filter: (mutation) => ['setLanguageOverride'].includes(mutation.type)
});
function createState() {
    return {
        showDrawer: false,
        languageOverride: undefined,
        errors: [],
        hideDefaultErrorBar: false
    };
}
export default new Vuex.Store({
    plugins: [vuexLocal.plugin],
    state: createState(),
    modules: {},
    mutations: {
        setShowDrawer(state, payload) {
            state.showDrawer = payload;
        },
        resetState(state) {
            Object.assign(state, createState());
        },
        setLanguageOverride(state, payload) {
            state.languageOverride = payload;
        },
        addError(state, error) {
            state.errors.push(error);
        },
        clearErrors(state) {
            state.errors.splice(0);
        },
        setHideDefaultErrorBar(state, payload) {
            state.hideDefaultErrorBar = payload;
        }
    },
    actions: {
        resetState(state) {
            state.commit('resetState');
        },
        async logout(store) {
            store.commit("setLoginData", null);
            store.dispatch('resetState');
            datadogRum.removeUser();
        }
    },
    getters: {
        IsAuthenticated(state) {
            return true;
        },
        userLanguage(state, getters) {
            return state.languageOverride ?? navigator.language;
        }
    }
});
