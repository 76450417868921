import { __decorate } from "tslib";
import { generateApiUrl } from "@/Helpers/BaseUrl";
import mainOidc from '@/plugins/vue-oidc-client/idsrvAuth';
import axios from 'axios';
import { Component, ModelSync, Prop, Vue, Watch } from "vue-property-decorator";
import DeleteButton from "../DeleteButton.vue";
import InspectionListAttachmentsAddButton from "./InspectionListAttachmentsAddButton.vue";
import InspectionListAttachmentThumbnail from './InspectionListAttachmentThumbnail.vue';
let InspectionListAttachmentsViewer = class InspectionListAttachmentsViewer extends Vue {
    filename;
    inspectionListKey;
    dialogSync;
    objectUrl = '';
    async mounted() {
        if (this.filename && this.inspectionListKey) {
            await this.getImage(this.filename);
        }
    }
    beforeUnmount() {
        URL.revokeObjectURL(this.objectUrl);
        this.objectUrl = '';
    }
    async onFileChange(newFile) {
        URL.revokeObjectURL(this.objectUrl);
        this.objectUrl = '';
        await this.getImage(newFile);
    }
    onInspectionChange() {
        URL.revokeObjectURL(this.objectUrl);
        this.objectUrl = '';
    }
    async getImage(filename) {
        const response = await axios
            .get(generateApiUrl('inspections/attachments') + `/${encodeURIComponent(this.inspectionListKey)}/${filename}`, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': mainOidc.isAuthenticated ? `Bearer ${mainOidc.accessToken}` : ''
            }
        });
        const blob = new Blob([response.data]);
        this.objectUrl = URL.createObjectURL(blob);
        return;
    }
};
__decorate([
    Prop({ type: String, required: true })
], InspectionListAttachmentsViewer.prototype, "filename", void 0);
__decorate([
    Prop({ type: String, required: true })
], InspectionListAttachmentsViewer.prototype, "inspectionListKey", void 0);
__decorate([
    ModelSync('dialog', 'onDialogChange', { type: Boolean, required: true })
], InspectionListAttachmentsViewer.prototype, "dialogSync", void 0);
__decorate([
    Watch('filename')
], InspectionListAttachmentsViewer.prototype, "onFileChange", null);
__decorate([
    Watch('inspectionListKey')
], InspectionListAttachmentsViewer.prototype, "onInspectionChange", null);
InspectionListAttachmentsViewer = __decorate([
    Component({
        components: {
            DeleteButton,
            InspectionListAttachmentThumbnail,
            InspectionListAttachmentsAddButton
        }
    })
], InspectionListAttachmentsViewer);
export default InspectionListAttachmentsViewer;
