export class TemplateChoiceValueUpdate {
    id = 0;
    value = '';
    sortOrder = 0;
    constructor(templateChoiceValue) {
        if (templateChoiceValue) {
            this.id = templateChoiceValue.id;
            this.value = templateChoiceValue.value;
            this.sortOrder = templateChoiceValue.sortOrder;
        }
    }
}
