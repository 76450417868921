import { __decorate } from "tslib";
import { TemplateChoiceValueUpdate } from "@/types/Api/Template/Update/TemplateChoiceValueUpdate";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import DeleteButton from "../DeleteButton.vue";
import MoveButton from "../MoveButton.vue";
import gql from "graphql-tag";
let ChoiceValueEdit = class ChoiceValueEdit extends Vue {
    editEnabled = true;
    value;
    hover;
    setEditMode(value) {
        if (!value && this.editEnabled) {
            this.doSave();
        }
    }
    doSave() {
        let updateObject = new TemplateChoiceValueUpdate(this.value);
        this.$promiseQueue.enqueue(() => {
            return new Promise((resolve, reject) => {
                this.$apollo.mutate({
                    mutation: gql `mutation ($input: UpdateTemplateChoiceValueInput!) 
          {
            updatedChoiceValue: updateTemplateChoiceValue(input: $input) {
              templateChoiceValue {
                id
                sortOrder
                value
              }
            }
          }`,
                    variables: {
                        input: { templateChoiceValue: updateObject }
                    },
                    fetchPolicy: "no-cache"
                }).then(result => {
                    Object.assign(this.value, result.data.updatedChoiceValue.templateChoiceValue);
                    resolve(this.value);
                }).catch(error => {
                    //Remove entry from the data.
                    reject(error);
                });
            });
        }, {
            dataObject: this.value,
            description: this.$t('update-choice-value', [this.value.value]).toString(),
            priority: 1
        }, true);
    }
    onKeyDown(event) {
        if (["Enter", "Esc", "Escape"].indexOf(event.key) > -1) {
            this.closeEditMode();
        }
    }
    closeEditMode() {
        this.setEditMode(false);
    }
    showEditMode() {
        this.setEditMode(true);
    }
    onDelete() {
        return this.value;
    }
    get supportTouch() {
        return ('ontouchstart' in window);
    }
};
__decorate([
    Prop({ type: Object, required: true, default: null })
], ChoiceValueEdit.prototype, "value", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], ChoiceValueEdit.prototype, "hover", void 0);
__decorate([
    Emit('delete')
], ChoiceValueEdit.prototype, "onDelete", null);
ChoiceValueEdit = __decorate([
    Component({
        components: { MoveButton, DeleteButton },
    })
], ChoiceValueEdit);
export default ChoiceValueEdit;
