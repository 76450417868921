import { __decorate } from "tslib";
import AppBar from "@/components/AppBar.vue";
import TemplateEditForm from "@/components/Templates/TemplateEditForm.vue";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import NoData from "../NoData.vue";
import TemplateEditSkeleton from "./TemplateEditSkeleton.vue";
let TemplateEditView = class TemplateEditView extends Vue {
    template;
    isLoading;
    templateEdit;
    validate() {
        return this.templateEdit.validate();
    }
};
__decorate([
    Prop({ type: Object, default: null })
], TemplateEditView.prototype, "template", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], TemplateEditView.prototype, "isLoading", void 0);
__decorate([
    Ref("templateEdit")
], TemplateEditView.prototype, "templateEdit", void 0);
TemplateEditView = __decorate([
    Component({
        components: {
            TemplateEditForm,
            NoData,
            AppBar,
            TemplateEditSkeleton
        },
    })
], TemplateEditView);
export default TemplateEditView;
