import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
let NumericalInspectionComponent = class NumericalInspectionComponent extends Vue {
    value;
    isDirty = false;
    showToleranceIcon = this.isOutsideTolerance();
    emitChange() {
        return this.value;
    }
    setDirty() {
        this.isDirty = true;
    }
    isOutsideTolerance() {
        if (this.value.numericalInspection?.measurement) {
            if ((this.value.numericalInspection.minValue && this.value.numericalInspection.measurement < this.value.numericalInspection.minValue) ||
                (this.value.numericalInspection.maxValue && this.value.numericalInspection.measurement > this.value.numericalInspection.maxValue)) {
                return true;
            }
        }
        return false;
    }
    onMeasurementValueChanged(measurementValue) {
        if (measurementValue === '-' || measurementValue === '+')
            return;
        if (this.value.numericalInspection == null)
            return;
        if (measurementValue) {
            this.value.numericalInspection.measurement = Number.parseFloat(measurementValue);
        }
        else {
            this.value.numericalInspection.measurement = undefined;
        }
    }
    onBlur() {
        this.showToleranceIcon = this.isOutsideTolerance();
        if (this.isDirty) {
            this.isDirty = false;
            this.emitChange();
        }
    }
};
__decorate([
    Prop({ type: Object, required: true, default: null })
], NumericalInspectionComponent.prototype, "value", void 0);
__decorate([
    Emit('change')
], NumericalInspectionComponent.prototype, "emitChange", null);
NumericalInspectionComponent = __decorate([
    Component({
        components: {},
    })
], NumericalInspectionComponent);
export default NumericalInspectionComponent;
