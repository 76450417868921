import { __decorate } from "tslib";
import gql from "graphql-tag";
import _ from 'lodash';
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DeleteButton from "../../DeleteButton.vue";
import MoveButton from "../../MoveButton.vue";
let InspectionListChoiceValueAutoComplete = class InspectionListChoiceValueAutoComplete extends Vue {
    inspectionListChoiceTypeId;
    selectedChoiceType = null;
    selectedId;
    selectedItem = null;
    isLoading = 0;
    onChoiceTypeChanged(newVal) {
        if (this.selectedId) {
            let selectedItem = this.selectedChoiceType?.choiceValues.find(item => item.id == this.selectedId);
            this.selectedItem = selectedItem;
        }
    }
    get sortedValues() {
        if (this.selectedChoiceType)
            return _.orderBy(this.selectedChoiceType.choiceValues, ['sortOrder', 'id']);
        else
            return [];
    }
    get getTitle() {
        if (this.selectedChoiceType && this.selectedChoiceType != null) {
            return this.selectedChoiceType.description;
        }
        return this.$t('choice-values').toString();
    }
};
__decorate([
    Prop({ type: Number, required: true, default: null })
], InspectionListChoiceValueAutoComplete.prototype, "inspectionListChoiceTypeId", void 0);
__decorate([
    Prop({ type: Number, required: true, default: null })
], InspectionListChoiceValueAutoComplete.prototype, "selectedId", void 0);
__decorate([
    Watch('selectedChoiceType')
], InspectionListChoiceValueAutoComplete.prototype, "onChoiceTypeChanged", null);
InspectionListChoiceValueAutoComplete = __decorate([
    Component({
        components: { MoveButton, DeleteButton },
        apollo: {
            selectedChoiceType: {
                loadingKey: "isLoading",
                query: gql `query inspectionListChoiceType($typeId: Int!) {
                  selectedChoiceType: inspectionListChoiceType(inspectionListChoiceTypeId: $typeId) {
                    id
                    description
                    choiceValues {
                      inspectionListChoiceTypeId
                      value
                      sortOrder
                      id
                    }
                  }
                }`,
                fetchPolicy: 'cache-first',
                variables() {
                    return { typeId: Number(this.inspectionListChoiceTypeId) };
                }
            }
        }
    })
], InspectionListChoiceValueAutoComplete);
export default InspectionListChoiceValueAutoComplete;
