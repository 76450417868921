import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
let SingleLineInspectionComponent = class SingleLineInspectionComponent extends Vue {
    value;
    onChange() {
        return this.value;
    }
};
__decorate([
    Prop({ type: Object, required: false, default: null })
], SingleLineInspectionComponent.prototype, "value", void 0);
__decorate([
    Emit('change')
], SingleLineInspectionComponent.prototype, "onChange", null);
SingleLineInspectionComponent = __decorate([
    Component({
        components: {},
    })
], SingleLineInspectionComponent);
export default SingleLineInspectionComponent;
