export class TemplateSingleLineInspectionUpdate {
    id = 0;
    description = '';
    constructor(SingleLineInspection) {
        if (SingleLineInspection) {
            this.id = SingleLineInspection.id;
            this.description = SingleLineInspection.description;
        }
    }
}
