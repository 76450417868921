import { __decorate } from "tslib";
import AppBarResponsiveButton from "@/components/AppBarResponsiveButton.vue";
import MenuItem from "@/types/MenuItem";
import _ from "lodash";
import { Component, Vue } from "vue-property-decorator";
let DefaultMenu = class DefaultMenu extends Vue {
    isLoading = 0;
    get menuItems() {
        let retVar = [];
        if (this.$router.options.routes != null) {
            this.$router.options.routes.forEach(route => {
                let routeMeta = route.meta;
                if (routeMeta) {
                    if (routeMeta.showInMenu) {
                        retVar.push(new MenuItem(route.name ?? "", routeMeta.menuIcon, route.path, routeMeta.menuOrder, routeMeta.menuTranslation));
                    }
                }
            });
            retVar = _.orderBy(retVar, ['order'], ['asc']);
        }
        return retVar;
    }
};
DefaultMenu = __decorate([
    Component({
        components: {
            AppBarResponsiveButton,
        }
    })
], DefaultMenu);
export default DefaultMenu;
