import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
import DeleteButton from "../DeleteButton.vue";
import InspectionListAttachmentsAddButton from "./InspectionListAttachmentsAddButton.vue";
import InspectionListAttachmentsViewer from "./InspectionListAttachmentsViewer.vue";
import InspectionListAttachmentThumbnail from './InspectionListAttachmentThumbnail.vue';
let InspectionListAttachments = class InspectionListAttachments extends Vue {
    inspectionList;
    dialog = false;
    activeImage = '';
    showImage(filename) {
        console.log(filename);
        this.activeImage = filename;
        this.dialog = true;
    }
};
__decorate([
    Prop({ type: Object, default: null })
], InspectionListAttachments.prototype, "inspectionList", void 0);
InspectionListAttachments = __decorate([
    Component({
        components: {
            DeleteButton,
            InspectionListAttachmentThumbnail,
            InspectionListAttachmentsAddButton,
            InspectionListAttachmentsViewer
        }
    })
], InspectionListAttachments);
export default InspectionListAttachments;
