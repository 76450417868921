import { __decorate } from "tslib";
import AppBarResponsiveButton from "@/components/AppBarResponsiveButton.vue";
import { goBack } from '@/Helpers/NavigationHelpers';
import mainOidc from '@/plugins/vue-oidc-client/idsrvAuth';
import { trackAction } from "@/store/Helpers/UserMonitor";
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import { Getter, Mutation, State } from 'vuex-class';
import UserMenu from './UserMenu.vue';
let AppBar = class AppBar extends Vue {
    title;
    showBackButton;
    showSearchBox;
    setShowDrawer;
    setLanguageOverride;
    showDrawer;
    userLanguage;
    searchQuery = '';
    onRouteChange() {
        this.searchQuery = '';
        //Manually send event, otherwise it will be debounced
        this.searchQueryChanged(this.searchQuery);
    }
    get userName() {
        return mainOidc.userProfile.name ?? '?';
    }
    get hasExtension() {
        return !!this.$slots.extension;
    }
    get currentTitle() {
        if (this.title)
            return this.title;
        else
            return this.$i18n.t('applicationTitle').toString();
    }
    goBack() {
        goBack();
    }
    onHelp() {
        let routeMeta = this.$router.currentRoute?.meta;
        let section = 'index';
        if (routeMeta?.helpSection) {
            section = routeMeta.helpSection;
        }
        window.open(`https://eu-manuals.ecisolutions.com/inspections/#/${section}/${this.userLanguage}/std`, '_blank');
    }
    get isMobile() {
        return this.$vuetify.breakpoint.mobile;
    }
    get isDesktop() {
        return !this.isMobile;
    }
    get isAuthenticated() {
        return mainOidc.isAuthenticated;
    }
    get canRouteShowDrawer() {
        return !this.$route.meta?.hideDrawer;
    }
    signout() {
        this.$router.push('/signout');
    }
    setLanguage(lang) {
        this.setLanguageOverride(lang);
    }
    isActiveLanguage(lang) {
        return this.userLanguage.startsWith(lang);
    }
    searchQueryChanged(q) {
        if (q) {
            trackAction("AppBar search");
        }
        return q;
    }
};
__decorate([
    Prop({ type: String, default: "" })
], AppBar.prototype, "title", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], AppBar.prototype, "showBackButton", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], AppBar.prototype, "showSearchBox", void 0);
__decorate([
    Mutation
], AppBar.prototype, "setShowDrawer", void 0);
__decorate([
    Mutation
], AppBar.prototype, "setLanguageOverride", void 0);
__decorate([
    State
], AppBar.prototype, "showDrawer", void 0);
__decorate([
    Getter
], AppBar.prototype, "userLanguage", void 0);
__decorate([
    Watch('$route')
], AppBar.prototype, "onRouteChange", null);
__decorate([
    Emit('searchQueryChanged')
], AppBar.prototype, "searchQueryChanged", null);
AppBar = __decorate([
    Component({
        components: {
            AppBarResponsiveButton,
            UserMenu
        },
    })
], AppBar);
export default AppBar;
