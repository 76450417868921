export class TemplateChoiceInspectionUpdate {
    id = 0;
    description = '';
    templateChoiceTypeId = undefined;
    constructor(choiceInspection) {
        if (choiceInspection) {
            this.id = choiceInspection.id;
            this.description = choiceInspection.description;
            if (choiceInspection.templateChoiceTypeId > 0)
                this.templateChoiceTypeId = choiceInspection.templateChoiceTypeId;
        }
    }
}
