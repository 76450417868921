export class InspectionListDateInspectionUpdate {
    id = 0;
    date = '';
    constructor(dateInspection) {
        if (dateInspection) {
            this.id = dateInspection.id;
            this.date = dateInspection.date;
        }
    }
}
