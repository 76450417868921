import { InspectionListBooleanInspectionUpdate } from "./InspectionListBooleanInspectionUpdate";
import { InspectionListNumericalInspectionUpdate } from "./InspectionListNumericalInspectionUpdate";
import { InspectionListSingleLineInspectionUpdate } from "./InspectionListSingleLineInspectionUpdate";
import { InspectionListMultiLineInspectionUpdate } from "./InspectionListMultiLineInspectionUpdate";
import { InspectionListChoiceInspectionUpdate } from "./InspectionListChoiceInspectionUpdate";
import { InspectionListDateInspectionUpdate } from "./InspectionListDateInspectionUpdate";
export class InspectionListInspectionUpdate {
    id = 0;
    booleanInspection;
    numericalInspection;
    singleLineInspection;
    multiLineInspection;
    choiceInspection;
    dateInspection;
    constructor(inspectionListInspection) {
        if (inspectionListInspection) {
            this.id = inspectionListInspection.id;
            if (inspectionListInspection.booleanInspection) {
                this.booleanInspection = new InspectionListBooleanInspectionUpdate(inspectionListInspection.booleanInspection);
            }
            else if (inspectionListInspection.numericalInspection) {
                this.numericalInspection = new InspectionListNumericalInspectionUpdate(inspectionListInspection.numericalInspection);
            }
            else if (inspectionListInspection.singleLineInspection) {
                this.singleLineInspection = new InspectionListSingleLineInspectionUpdate(inspectionListInspection.singleLineInspection);
            }
            else if (inspectionListInspection.multiLineInspection) {
                this.multiLineInspection = new InspectionListMultiLineInspectionUpdate(inspectionListInspection.multiLineInspection);
            }
            else if (inspectionListInspection.choiceInspection) {
                this.choiceInspection = new InspectionListChoiceInspectionUpdate(inspectionListInspection.choiceInspection);
            }
            else if (inspectionListInspection.dateInspection) {
                this.dateInspection = new InspectionListDateInspectionUpdate(inspectionListInspection.dateInspection);
            }
            else {
                console.error("No mandatory boolean/numerical/image/single/multiline/choice/date-inspection inspection object.");
            }
        }
    }
}
