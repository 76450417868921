import { __decorate } from "tslib";
import AppBarResponsiveButton from "@/components/AppBarResponsiveButton.vue";
import ChoiceTypeEditView from "@/components/ChoiceTypes/ChoiceTypeEditView.vue";
import DefaultMenu from "@/components/DefaultMenu.vue";
import { goBack } from "@/Helpers/NavigationHelpers";
import gql from "graphql-tag";
import { Component, Vue } from "vue-property-decorator";
let TemplateChoiceTypeEdit = class TemplateChoiceTypeEdit extends Vue {
    selectedChoiceType = null;
    isLoading = 0;
    goBack() {
        goBack();
    }
};
TemplateChoiceTypeEdit = __decorate([
    Component({
        components: {
            AppBarResponsiveButton,
            ChoiceTypeEditView,
            DefaultMenu
        },
        apollo: {
            selectedChoiceType: {
                loadingKey: "isLoading",
                query: gql `query templateChoiceType($typeId: Int!) {
                  selectedChoiceType: templateChoiceType(templateChoiceTypeId: $typeId) {
                    id
                    description
                    choiceValues {
                      templateChoiceTypeId
                      value
                      sortOrder
                      id
                    }
                  }
                }`,
                fetchPolicy: 'network-only',
                variables() {
                    return { typeId: Number(this.$route.params['id']) };
                }
            }
        }
    })
], TemplateChoiceTypeEdit);
export default TemplateChoiceTypeEdit;
