import { __decorate } from "tslib";
import { TemplateInput } from '@/types/Api/Template/Input/TemplateInput';
import gql from 'graphql-tag';
import { Component, ModelSync, Ref, Vue, Watch } from "vue-property-decorator";
import copyLinkButton from '../CopyLinkButton.vue';
import { trackAction } from "@/store/Helpers/UserMonitor";
import { Mutation } from 'vuex-class';
import ErrorBar from '../ErrorBar.vue';
let TemplateAddDialog = class TemplateAddDialog extends Vue {
    showDialogValue;
    setHideDefaultErrorBar;
    clearErrors;
    identifier = '';
    description = '';
    async mounted() {
        this.identifier = '';
    }
    onVisibleChanged(value) {
        if (!value) {
            this.identifier = '';
            this.description = '';
            this.setHideDefaultErrorBar(false);
            this.clearErrors();
        }
        else {
            this.setHideDefaultErrorBar(true);
        }
    }
    identifierTextField;
    hide() {
        this.showDialogValue = false;
    }
    get canSubmit() {
        if (this.identifier && this.description) {
            return true;
        }
        return false;
    }
    save() {
        if (!this.canSubmit) {
            return;
        }
        const templateInput = new TemplateInput();
        templateInput.key = this.identifier;
        templateInput.description = this.description;
        this.$apollo.mutate({
            mutation: gql `mutation ($input: AddTemplateInput!) {
        resultData: addTemplate(input: $input) {
          template {
            key
          }
        }
      }`,
            variables: {
                input: { template: templateInput }
            }
        }).then((result) => {
            this.$router.push({ name: "TemplateEdit", params: { key: result.data.resultData.template.key } });
            this.identifier = '';
            this.description = '';
            this.showDialogValue = false;
            trackAction("Template added");
        });
    }
};
__decorate([
    ModelSync('showDialog', 'visiblityChanged', { type: Boolean, required: true })
], TemplateAddDialog.prototype, "showDialogValue", void 0);
__decorate([
    Mutation
], TemplateAddDialog.prototype, "setHideDefaultErrorBar", void 0);
__decorate([
    Mutation
], TemplateAddDialog.prototype, "clearErrors", void 0);
__decorate([
    Watch('showDialogValue')
], TemplateAddDialog.prototype, "onVisibleChanged", null);
__decorate([
    Ref('identifierTextField')
], TemplateAddDialog.prototype, "identifierTextField", void 0);
TemplateAddDialog = __decorate([
    Component({
        components: {
            copyLinkButton,
            ErrorBar
        },
    })
], TemplateAddDialog);
export default TemplateAddDialog;
