export class InspectionListBooleanInspectionUpdate {
    id = 0;
    value = undefined;
    constructor(booleanInspection) {
        if (booleanInspection) {
            this.id = booleanInspection.id;
            this.value = booleanInspection.value;
        }
    }
}
