import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import SkeletonEditField from "@/components/SkeletonEditField.vue";
let TemplateEditSkeleton = class TemplateEditSkeleton extends Vue {
};
TemplateEditSkeleton = __decorate([
    Component({
        components: {
            SkeletonEditField
        },
    })
], TemplateEditSkeleton);
export default TemplateEditSkeleton;
