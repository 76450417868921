import { __decorate } from "tslib";
import { trackAction } from "@/store/Helpers/UserMonitor";
import { TemplateChoiceValueInput } from "@/types/Api/Template/Input/TemplateChoiceValueInput";
import { TemplateChoiceValue } from "@/types/Api/Template/TemplateChoiceValue";
import { TemplateChoiceTypeUpdate } from "@/types/Api/Template/Update/TemplateChoiceTypeUpdate";
import gql from "graphql-tag";
import _, { maxBy, orderBy } from "lodash";
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import draggable from 'vuedraggable';
import { State } from "vuex-class";
import TCHover from "../TrivestComponents/TCHover/TCHover.vue";
import ChoiceTypeDescriptionEdit from "./ChoiceTypeDescriptionEdit.vue";
import ChoiceValueEdit from "./ChoiceValueEdit.vue";
let ChoiceTypeEditForm = class ChoiceTypeEditForm extends Vue {
    templateChoiceType;
    isLoadingData;
    debouncedSaveFn;
    dragging = false;
    onDescriptionChanged() {
        if (this.debouncedSaveFn)
            this.debouncedSaveFn();
    }
    created() {
        this.debouncedSaveFn = _.debounce(this.doSave, 2000, { trailing: true });
    }
    get sortedValues() {
        return orderBy(this.templateChoiceType.choiceValues, ['sortOrder', 'id'], ['asc', 'asc']);
    }
    set sortedValues(value) {
        this.templateChoiceType.choiceValues = value;
    }
    titleEdit;
    validate() {
        return this.titleEdit.validate();
    }
    doSave() {
        if (!this.validate()) {
            return;
        }
        let updateObject = new TemplateChoiceTypeUpdate(this.templateChoiceType);
        this.$promiseQueue.enqueue(() => {
            return new Promise((resolve, reject) => {
                this.$apollo.mutate({
                    mutation: gql `mutation ($input: UpdateTemplateChoiceTypeInput!) 
          {
            updatedChoiceType: updateTemplateChoiceType(input: $input) {
              templateChoiceType {
                id
                description
              }
            }
          }`,
                    variables: {
                        input: { templateChoiceType: updateObject }
                    },
                    fetchPolicy: "no-cache"
                }).then(result => {
                    Object.assign(this.templateChoiceType, result.data.updatedChoiceType.templateChoiceType);
                    resolve(this.templateChoiceType);
                }).catch(error => {
                    reject(error);
                });
            });
        }, {
            dataObject: this.templateChoiceType,
            description: this.$t('update-choice-type', [updateObject.description]).toString(),
            priority: 1
        }, true);
    }
    getSortOrderForNewEntry() {
        if (!this.templateChoiceType?.choiceValues || !this.templateChoiceType.choiceValues.length)
            return 0;
        const numValues = this.templateChoiceType.choiceValues.length;
        const maxSortOrder = maxBy(this.templateChoiceType.choiceValues, (o) => o.sortOrder)?.sortOrder;
        if (!maxSortOrder)
            return numValues;
        else if (maxSortOrder < numValues)
            return numValues;
        else
            return maxSortOrder + 1;
    }
    addChoiceValue() {
        let choiceValue = new TemplateChoiceValue();
        choiceValue.templateChoiceTypeId = this.templateChoiceType.id;
        choiceValue.sortOrder = this.getSortOrderForNewEntry();
        this.templateChoiceType.choiceValues.push(choiceValue);
        let updateObject = new TemplateChoiceValueInput(choiceValue);
        this.$promiseQueue.enqueue(() => {
            return new Promise((resolve, reject) => {
                this.$apollo.mutate({
                    mutation: gql `mutation ($input: AddTemplateChoiceValueInput!) 
          {
            insertedChoiceValue: addTemplateChoiceValue(input: $input) {
              templateChoiceValue {
                id
                sortOrder
                value
              }
            }
          }`,
                    variables: {
                        input: { templateChoiceValue: updateObject }
                    },
                    fetchPolicy: "no-cache"
                }).then(result => {
                    Object.assign(choiceValue, result.data.insertedChoiceValue.templateChoiceValue);
                    resolve(choiceValue);
                }).catch(error => {
                    //Remove entry from the data.
                    this.templateChoiceType.choiceValues.splice(this.templateChoiceType.choiceValues.indexOf(choiceValue), 1);
                    reject(error);
                });
            });
        }, {
            dataObject: updateObject,
            description: this.$t('add-choice-value').toString(),
            priority: 10
        });
    }
    deleteChoiceValue(value) {
        let idx = this.templateChoiceType.choiceValues.indexOf(value);
        if (idx >= 0) {
            this.templateChoiceType.choiceValues.splice(idx, 1);
        }
        else {
            console.error(`Choice value ${value.id}:${value.value} not found`);
            console.error(value);
            return;
        }
        this.$promiseQueue.enqueue(() => {
            return new Promise((resolve, reject) => {
                this.$apollo.mutate({
                    mutation: gql `mutation ($input: DeleteTemplateChoiceValueInput!) 
          {
            deletedChoiceValue: deleteTemplateChoiceValue(input: $input) {
              templateChoiceValue {
                id
              }
            }
          }`,
                    variables: {
                        input: { templateChoiceValueId: Number(value.id) }
                    },
                    fetchPolicy: "no-cache"
                }).then(result => {
                    resolve(value);
                }).catch(error => {
                    this.templateChoiceType.choiceValues.splice(idx, 0, value);
                    reject(error);
                });
            });
        }, {
            dataObject: value,
            description: this.$t('delete-choice-value-value', [value.value]).toString(),
            priority: 1
        }, true);
    }
    onDragEnd(evt) {
        this.dragging = false;
        document.dispatchEvent(new Event('mousemove'));
    }
    updateListSortOrder(arg) {
        trackAction("Change choice value order");
        const newList = [...this.templateChoiceType.choiceValues].map((item, index) => {
            const newSort = index;
            item.orderChanged = item.sortOrder !== newSort;
            if (item.orderChanged) {
                item.sortOrder = newSort;
            }
            return item;
        });
        this.templateChoiceType.choiceValues = newList;
        const changedItems = newList.filter(item => item.orderChanged == true).map(item => {
            return { id: item.id, sortOrder: item.sortOrder };
        });
        this.$promiseQueue.enqueue(() => {
            return new Promise((resolve, reject) => {
                this.$apollo.mutate({
                    mutation: gql `mutation ($input: UpdateTemplateChoiceValueSortOrderInput!) 
          {
            updated: updateTemplateChoiceValueSortOrder(input: $input) {
              templateChoiceValuesSortOrder {
                templateChoiceValueItems {
                  id
                  sortOrder
                }
              }
            }
          }`,
                    variables: {
                        input: {
                            templateChoiceValuesSortOrder: {
                                templateChoiceValueItems: changedItems
                            }
                        }
                    },
                    fetchPolicy: "no-cache"
                }).then(result => {
                    resolve(this.templateChoiceType);
                }).catch(error => {
                    reject(error);
                });
            });
        }, { dataObject: this.templateChoiceType, description: this.$t('saving-choice-value-sort-order').toString() }, false);
    }
};
__decorate([
    Prop({ type: Object, default: null })
], ChoiceTypeEditForm.prototype, "templateChoiceType", void 0);
__decorate([
    State
], ChoiceTypeEditForm.prototype, "isLoadingData", void 0);
__decorate([
    Watch('templateChoiceType.description')
], ChoiceTypeEditForm.prototype, "onDescriptionChanged", null);
__decorate([
    Ref("titleEdit")
], ChoiceTypeEditForm.prototype, "titleEdit", void 0);
ChoiceTypeEditForm = __decorate([
    Component({
        components: {
            draggable,
            TCHover,
            ChoiceTypeDescriptionEdit,
            ChoiceValueEdit
        }
    })
], ChoiceTypeEditForm);
export default ChoiceTypeEditForm;
