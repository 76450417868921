import { __decorate } from "tslib";
import InspectionTitleEdit from "@/components/InspectionList/InspectionTitleEdit.vue";
import BooleanInspectionEdit from "@/components/InspectionEntries/BooleanInspection/BooleanInspectionEdit.vue";
import NumericalInspectionEdit from "@/components/InspectionEntries/NumericalInspection/NumericalInspectionEdit.vue";
import ImageInspectionEdit from "@/components/InspectionEntries/ImageInspection/ImageInspectionEdit.vue";
import SingleLineTextInspectionEdit from "../InspectionEntries/TextInspection/SingleLineTextInspectionEdit.vue";
import MultiLineTextInspectionEdit from "../InspectionEntries/TextInspection/MultiLineTextInspectionEdit.vue";
import ChoiceInspectionEdit from "../InspectionEntries/ChoiceInspection/ChoiceInspectionEdit.vue";
import DateInspectionEdit from "../InspectionEntries/DateInspection/DateInspectionEdit.vue";
import { trackAction } from "@/store/Helpers/UserMonitor";
import gql from "graphql-tag";
import { orderBy } from "lodash";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import draggable from 'vuedraggable';
import TCHover from "../TrivestComponents/TCHover/TCHover.vue";
let TemplateInspectionGroupComponent = class TemplateInspectionGroupComponent extends Vue {
    inspectionGroup;
    templateKey;
    dragging = false;
    onDelete(templateInspection) {
        trackAction("Delete inspection");
        let idx = this.inspectionGroup.inspections.indexOf(templateInspection);
        if (idx > -1) {
            this.inspectionGroup.inspections.splice(idx, 1);
        }
        let description = this.getDeleteDescription(templateInspection);
        this.$promiseQueue.enqueue(() => {
            return new Promise((resolve, reject) => {
                this.$apollo.mutate({
                    mutation: gql `mutation ($input: DeleteTemplateInspectionInput!) 
          {
            deletedInspection: deleteTemplateInspection(input: $input) {
              templateInspection {
                id
              }
            }
          }`,
                    variables: {
                        input: { templateInspectionId: Number(templateInspection.id) }
                    },
                    fetchPolicy: "no-cache"
                }).then(result => {
                    //todo: Remove item from cache?
                    resolve(templateInspection);
                }).catch(error => {
                    //There was an error, re-insert item in list
                    this.inspectionGroup.inspections.splice(idx, 0, templateInspection);
                    reject(error);
                });
            });
        }, { dataObject: templateInspection, description: description, priority: 2 }, true);
    }
    getDeleteDescription(inspection) {
        let itemDescription = inspection.booleanInspection?.description ??
            inspection.multiLineInspection?.description ??
            inspection.singleLineInspection?.description ??
            inspection.numericalInspection?.description ??
            inspection.imageInspection?.description ??
            inspection.choiceInspection?.description ??
            inspection.dateInspection?.description;
        if (!itemDescription)
            itemDescription = this.$t('inspectionTitle').toString();
        let description = this.$t('deleting-inspection-description', [itemDescription]).toString();
        return description;
    }
    get sortedInspections() {
        return orderBy(this.inspectionGroup.inspections, ['sortOrder', 'id'], ['asc', 'asc']);
    }
    set sortedInspections(value) {
        this.inspectionGroup.inspections = value;
    }
    titleEdit;
    validate() {
        return this.titleEdit.validate();
    }
    onDragEnd(evt) {
        this.dragging = false;
        document.dispatchEvent(new Event('mousemove'));
    }
    updateListSortOrder(arg) {
        trackAction("Change inspection order");
        const newList = [...this.inspectionGroup.inspections].map((item, index) => {
            const newSort = index;
            // also add in a new property called has changed if you want to style them / send an api call
            item.orderChanged = item.sortOrder !== newSort;
            if (item.orderChanged) {
                item.sortOrder = newSort;
            }
            return item;
        });
        this.inspectionGroup.inspections = newList;
        const changedItems = newList.filter(item => item.orderChanged == true).map(item => {
            return { id: item.id, sortOrder: item.sortOrder };
        });
        this.$promiseQueue.enqueue(() => {
            return new Promise((resolve, reject) => {
                this.$apollo.mutate({
                    mutation: gql `mutation ($input: UpdateTemplateInspectionSortOrderInput!) 
          {
            updated: updateTemplateInspectionSortOrder(input: $input) {
              templateInspectionsSortOrder {
                templateInspectionItems {
                  id
                  sortOrder
                }
              }
            }
          }`,
                    variables: {
                        input: {
                            templateInspections: {
                                templateInspectionItems: changedItems
                            }
                        }
                    },
                    fetchPolicy: "no-cache"
                }).then(result => {
                    resolve(this.inspectionGroup);
                }).catch(error => {
                    reject(error);
                });
            });
        }, { dataObject: this.inspectionGroup, description: this.$t('saving-inspection-order').toString() }, false);
    }
};
__decorate([
    Prop({ type: Object, default: null })
], TemplateInspectionGroupComponent.prototype, "inspectionGroup", void 0);
__decorate([
    Prop({ type: String, required: true })
], TemplateInspectionGroupComponent.prototype, "templateKey", void 0);
__decorate([
    Ref("titleEdit")
], TemplateInspectionGroupComponent.prototype, "titleEdit", void 0);
TemplateInspectionGroupComponent = __decorate([
    Component({
        components: {
            InspectionTitleEdit,
            BooleanInspectionEdit,
            NumericalInspectionEdit,
            ImageInspectionEdit,
            SingleLineTextInspectionEdit,
            MultiLineTextInspectionEdit,
            ChoiceInspectionEdit,
            DateInspectionEdit,
            draggable,
            TCHover
        }
    })
], TemplateInspectionGroupComponent);
export default TemplateInspectionGroupComponent;
