import { __decorate } from "tslib";
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { setTimeout } from 'timers';
let TrivestButton = class TrivestButton extends Vue {
    finished;
    error;
    animationTimer;
    // eslint-disable-next-line no-prototype-builtins
    isTextButton = this.$attrs.hasOwnProperty('text');
    onFinished(val) {
        if (val) {
            setTimeout(() => {
                this.$emit('button-loader-finished');
            }, this.animationTimer);
        }
    }
    onError(val) {
        if (val) {
            setTimeout(() => {
                this.$emit('button-loader-error');
            }, this.animationTimer);
        }
    }
    classObjectSpan = {
        'font-weight-bold': !this.isTextButton,
        'primary--text': this.isTextButton
    };
};
__decorate([
    Prop({ type: Boolean, default: false, required: false })
], TrivestButton.prototype, "finished", void 0);
__decorate([
    Prop({ type: Boolean, default: false, required: false })
], TrivestButton.prototype, "error", void 0);
__decorate([
    Prop({ type: Number, required: false, default: 1250 })
], TrivestButton.prototype, "animationTimer", void 0);
__decorate([
    Watch('finished')
], TrivestButton.prototype, "onFinished", null);
__decorate([
    Watch('error')
], TrivestButton.prototype, "onError", null);
TrivestButton = __decorate([
    Component
], TrivestButton);
export default TrivestButton;
