import TrivestComponents from '@/components/TrivestComponents';
import { addI18nPlugin } from './i18n';
import { addVuelidatePlugin } from './vuelidate';
import { addErrorExtractorPlugin } from './vuelidate-error-extractor';
import { addVueApolloPlugin } from './vue-apollo';
import PortalVue from 'portal-vue';
import { addPQueuePlugin } from './promiseQueue';
export default function addPlugins(Vue) {
    addI18nPlugin(Vue);
    addVuelidatePlugin(Vue);
    addErrorExtractorPlugin(Vue);
    addVueApolloPlugin(Vue);
    Vue.use(TrivestComponents);
    Vue.use(PortalVue);
    addPQueuePlugin(Vue);
}
