import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import SkeletonEditField from "@/components/SkeletonEditField.vue";
let TemplateListItemSkeleton = class TemplateListItemSkeleton extends Vue {
};
TemplateListItemSkeleton = __decorate([
    Component({
        components: {
            SkeletonEditField
        },
    })
], TemplateListItemSkeleton);
export default TemplateListItemSkeleton;
