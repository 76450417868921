import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
let DeleteButton = class DeleteButton extends Vue {
    elevateOnHover;
    extraElevation;
    elevate;
};
__decorate([
    Prop({ type: Boolean, required: false, default: true })
], DeleteButton.prototype, "elevateOnHover", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], DeleteButton.prototype, "extraElevation", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], DeleteButton.prototype, "elevate", void 0);
DeleteButton = __decorate([
    Component({
        components: {},
    })
], DeleteButton);
export default DeleteButton;
