import { __decorate } from "tslib";
import { trackAction } from "@/store/Helpers/UserMonitor";
import { TemplateChoiceTypeInput } from "@/types/Api/Template/Input/TemplateChoiceTypeInput";
import gql from 'graphql-tag';
import { Component, ModelSync, Ref, Vue, Watch } from "vue-property-decorator";
import { Mutation } from 'vuex-class';
import ErrorBar from '../ErrorBar.vue';
let ChoiceTypeAddDialog = class ChoiceTypeAddDialog extends Vue {
    showDialogValue;
    setHideDefaultErrorBar;
    clearErrors;
    description = '';
    async mounted() {
        this.description = '';
    }
    onVisibleChanged(value) {
        if (!value) {
            this.description = '';
            this.setHideDefaultErrorBar(false);
            this.clearErrors();
        }
        else {
            this.setHideDefaultErrorBar(true);
        }
    }
    identifierTextField;
    hide() {
        this.showDialogValue = false;
    }
    get canSubmit() {
        if (this.description) {
            return true;
        }
        return false;
    }
    save() {
        if (!this.canSubmit) {
            return;
        }
        const templatechoiceTypeInput = new TemplateChoiceTypeInput();
        templatechoiceTypeInput.description = this.description;
        this.$apollo.mutate({
            mutation: gql `mutation ($input: AddTemplateChoiceTypeInput!) {
        resultData: addTemplateChoiceType(input: $input) {
          templateChoiceType {
            id
          }
        }
      }`,
            variables: {
                input: { templateChoiceType: templatechoiceTypeInput }
            }
        }).then((result) => {
            this.$router.push({ name: "TemplateChoiceTypeEdit", params: { id: result.data.resultData.templateChoiceType.id } });
            this.description = '';
            this.showDialogValue = false;
            trackAction("Template choice type added");
        });
    }
};
__decorate([
    ModelSync('showDialog', 'visiblityChanged', { type: Boolean, required: true })
], ChoiceTypeAddDialog.prototype, "showDialogValue", void 0);
__decorate([
    Mutation
], ChoiceTypeAddDialog.prototype, "setHideDefaultErrorBar", void 0);
__decorate([
    Mutation
], ChoiceTypeAddDialog.prototype, "clearErrors", void 0);
__decorate([
    Watch('showDialogValue')
], ChoiceTypeAddDialog.prototype, "onVisibleChanged", null);
__decorate([
    Ref('identifierTextField')
], ChoiceTypeAddDialog.prototype, "identifierTextField", void 0);
ChoiceTypeAddDialog = __decorate([
    Component({
        components: {
            ErrorBar
        },
    })
], ChoiceTypeAddDialog);
export default ChoiceTypeAddDialog;
