import { __decorate } from "tslib";
import { TemplateInspectionUpdate } from "@/types/Api/Template/Update/TemplateInspectionUpdate";
import gql from "graphql-tag";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import DeleteButton from "../../DeleteButton.vue";
import MoveButton from "../../MoveButton.vue";
let MultiLineTextInspectionEdit = class MultiLineTextInspectionEdit extends Vue {
    editEnabled = false;
    value;
    hover;
    setEditMode(value) {
        if (!value && this.editEnabled) {
            this.doSave();
        }
        this.editEnabled = value;
    }
    doSave() {
        this.$promiseQueue.enqueue(() => {
            let updateObject = new TemplateInspectionUpdate(this.value);
            return new Promise((resolve, reject) => {
                this.$apollo.mutate({
                    mutation: gql `mutation ($input: UpdateTemplateInspectionInput!) 
          {
            updatedInspection: updateTemplateInspection(input: $input) {
              templateInspection {
                id
                sortOrder
              }
            }
          }`,
                    variables: {
                        input: { templateInspection: updateObject }
                    },
                    fetchPolicy: "no-cache"
                }).then(result => {
                    resolve(this.value);
                }).catch(error => {
                    reject(error);
                });
            });
        }, {
            dataObject: this.value,
            description: this.$t('update-inspection-edit-description', [this.getDescription()]).toString(),
        }, true);
    }
    getDescription() {
        if (this.value.multiLineInspection?.description)
            return this.value.multiLineInspection.description;
        else
            return this.$t('inspectionTitle');
    }
    onKeyDown(event) {
        if (["Enter", "Esc", "Escape"].indexOf(event.key) > -1) {
            this.closeEditMode();
        }
    }
    closeEditMode() {
        this.setEditMode(false);
    }
    showEditMode() {
        this.setEditMode(true);
    }
    onDelete() {
        return true;
    }
    get supportTouch() {
        return ('ontouchstart' in window);
    }
};
__decorate([
    Prop({ type: Object, required: false, default: null })
], MultiLineTextInspectionEdit.prototype, "value", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], MultiLineTextInspectionEdit.prototype, "hover", void 0);
__decorate([
    Emit('delete')
], MultiLineTextInspectionEdit.prototype, "onDelete", null);
MultiLineTextInspectionEdit = __decorate([
    Component({
        components: { MoveButton, DeleteButton },
    })
], MultiLineTextInspectionEdit);
export default MultiLineTextInspectionEdit;
