import { __decorate } from "tslib";
import InspectionTitleEdit from "@/components/InspectionList/InspectionTitleEdit.vue";
import { TemplateInspection } from "@/types/Api/Template/TemplateInspection";
import { TemplateInspectionInput } from "@/types/Api/Template/Input/TemplateInspectionInput";
import { TemplateBooleanInspection } from "@/types/Api/Template/TemplateBooleanInspection";
import { TemplateNumericalInspection } from "@/types/Api/Template/TemplateNumericalInspection";
import { TemplateImageInspection } from "@/types/Api/Template/TemplateImageInspection";
import { TemplateSingleLineInspection } from "@/types/Api/Template/TemplateSingleLineInspection";
import { TemplateMultiLineInspection } from "@/types/Api/Template/TemplateMultiLineInspection";
import { TemplateChoiceInspection } from "@/types/Api/Template/TemplateChoiceInspection";
import { TemplateDateInspection } from "@/types/Api/Template/TemplateDateInspection";
import { trackAction } from "@/store/Helpers/UserMonitor";
import gql from "graphql-tag";
import { maxBy } from 'lodash';
import { Component, Prop, Vue } from "vue-property-decorator";
let TemplateEditMenu = class TemplateEditMenu extends Vue {
    template;
    getSortOrderForNewEntry(group) {
        if (!group || !group.inspections.length)
            return 0;
        const numInspections = group.inspections.length;
        const maxSortOrder = maxBy(this.template?.groups[0].inspections, (o) => o.sortOrder)?.sortOrder;
        if (!maxSortOrder)
            return numInspections;
        else if (maxSortOrder < numInspections)
            return numInspections;
        else
            return maxSortOrder + 1;
    }
    addBooleanInspection() {
        var inspectionEntry = new TemplateInspection();
        inspectionEntry.booleanInspection = new TemplateBooleanInspection();
        inspectionEntry.sortOrder = this.getSortOrderForNewEntry(this.template?.groups[0]);
        inspectionEntry.inspectionGroupId = this.template?.groups[0].id;
        this.template?.groups[0].inspections.push(inspectionEntry);
        this.saveInspection(inspectionEntry, this.$t('add-boolean-inspection').toString());
        trackAction("Add boolean inspection");
    }
    addNumericalInspection() {
        var inspectionEntry = new TemplateInspection();
        inspectionEntry.numericalInspection = new TemplateNumericalInspection();
        inspectionEntry.sortOrder = this.getSortOrderForNewEntry(this.template?.groups[0]);
        inspectionEntry.inspectionGroupId = this.template?.groups[0].id;
        this.template?.groups[0].inspections.push(inspectionEntry);
        this.saveInspection(inspectionEntry, this.$t('add-numerical-inspection').toString());
        trackAction("Add numerical inspection");
    }
    addImageInspection() {
        var inspectionEntry = new TemplateInspection();
        inspectionEntry.imageInspection = new TemplateImageInspection();
        inspectionEntry.sortOrder = this.getSortOrderForNewEntry(this.template?.groups[0]);
        inspectionEntry.inspectionGroupId = this.template?.groups[0].id;
        this.template?.groups[0].inspections.push(inspectionEntry);
        this.saveInspection(inspectionEntry, this.$t('add-image-inspection').toString());
        trackAction("Add image inspection");
    }
    addMultiLineInspection() {
        var inspectionEntry = new TemplateInspection();
        inspectionEntry.multiLineInspection = new TemplateMultiLineInspection();
        inspectionEntry.sortOrder = this.getSortOrderForNewEntry(this.template?.groups[0]);
        inspectionEntry.inspectionGroupId = this.template?.groups[0].id;
        this.template?.groups[0].inspections.push(inspectionEntry);
        this.saveInspection(inspectionEntry, this.$t('add-multi-line-inspection').toString());
        trackAction("Add multi line inspection");
    }
    addSingleLineInspection() {
        var inspectionEntry = new TemplateInspection();
        inspectionEntry.singleLineInspection = new TemplateSingleLineInspection();
        inspectionEntry.sortOrder = this.getSortOrderForNewEntry(this.template?.groups[0]);
        inspectionEntry.inspectionGroupId = this.template?.groups[0].id;
        this.template?.groups[0].inspections.push(inspectionEntry);
        this.saveInspection(inspectionEntry, this.$t('add-single-line-inspection').toString());
        trackAction("Add single line inspection");
    }
    addChoiceInspection() {
        var inspectionEntry = new TemplateInspection();
        inspectionEntry.choiceInspection = new TemplateChoiceInspection();
        inspectionEntry.sortOrder = this.getSortOrderForNewEntry(this.template?.groups[0]);
        inspectionEntry.inspectionGroupId = this.template?.groups[0].id;
        this.template?.groups[0].inspections.push(inspectionEntry);
        this.saveInspection(inspectionEntry, this.$t('add-choice-inspection').toString());
        trackAction("Add choice inspection");
    }
    addDateInspection() {
        var inspectionEntry = new TemplateInspection();
        inspectionEntry.dateInspection = new TemplateDateInspection();
        inspectionEntry.sortOrder = this.getSortOrderForNewEntry(this.template?.groups[0]);
        inspectionEntry.inspectionGroupId = this.template?.groups[0].id;
        this.template?.groups[0].inspections.push(inspectionEntry);
        this.saveInspection(inspectionEntry, this.$t('add-date-inspection').toString());
        trackAction("Add date inspection");
    }
    saveInspection(inspectionEntry, title) {
        let updateObject = new TemplateInspectionInput(inspectionEntry);
        this.$promiseQueue.enqueue(() => {
            return new Promise((resolve, reject) => {
                this.$apollo.mutate({
                    mutation: gql `mutation ($input: AddTemplateInspectionInput!) 
          {
            insertedInspection: addTemplateInspection(input: $input) {
              templateInspection {
                id
                sortOrder
                booleanInspection {
                  id
                }
                numericalInspection {
                  id
                }
                imageInspection {
                  id
                }
                singleLineInspection {
                  id
                }
                multiLineInspection {
                  id
                }
                choiceInspection {
                  id
                }
                dateInspection {
                  id
                }
              }
            }
          }`,
                    variables: {
                        input: { templateInspection: updateObject }
                    },
                    fetchPolicy: "no-cache"
                }).then(result => {
                    Object.assign(inspectionEntry, result.data.insertedInspection.templateInspection);
                    resolve(inspectionEntry);
                }).catch(error => {
                    //Remove entry from the data.
                    this.template.groups[0].inspections.splice(this.template.groups[0].inspections.indexOf(inspectionEntry), 1);
                    reject(error);
                });
            });
        }, {
            dataObject: updateObject,
            description: title,
            //Use higher priority, so all subsequent calls have an id
            priority: 10
        });
    }
};
__decorate([
    Prop({ type: Object, default: null })
], TemplateEditMenu.prototype, "template", void 0);
TemplateEditMenu = __decorate([
    Component({
        components: {
            InspectionTitleEdit,
        }
    })
], TemplateEditMenu);
export default TemplateEditMenu;
