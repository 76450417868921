import { __decorate } from "tslib";
import AppBar from "@/components/AppBar.vue";
import TemplateListItemSkeleton from "@/components/Templates/TemplateListItemSkeleton.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import NoData from "../NoData.vue";
import CopyLinkButton from "@/components/CopyLinkButton.vue";
import DeleteButton from "../DeleteButton.vue";
import CopyButton from "../CopyButton.vue";
import TemplateCopyDialog from "@/components/Templates/TemplateCopyDialog.vue";
import { trackAction } from "@/store/Helpers/UserMonitor";
let TemplateList = class TemplateList extends Vue {
    templates;
    isLoading;
    hasMore;
    searchQuery;
    showTemplateDeleteDialog = false;
    showTemplateCopyDialog = false;
    templateToDelete;
    templateKeyToCopy = '';
    copiedUrl = false;
    showCopyDialog(template) {
        this.templateKeyToCopy = template.key;
        this.showTemplateCopyDialog = true;
        trackAction("Show copy template dialog");
    }
    showDeleteDialog(template) {
        this.templateToDelete = template;
        this.showTemplateDeleteDialog = true;
        trackAction("Show delete template dialog");
    }
    openTemplate(template) {
        this.$router.push(this.getTemplateRoute(template));
    }
    getCols(isHovering) {
        return isHovering ? 10 : 11;
    }
    getTemplateRoute(template) {
        return { name: "TemplateEdit", params: { key: template.key } };
    }
    get supportTouch() {
        return ('ontouchstart' in window);
    }
    addTemplate() {
        this.$emit('add-template');
    }
    loadMoreData(entries, observer, intersecting) {
        if (intersecting) {
            this.$emit('load-more-data');
        }
    }
    deleteTemplate() {
        if (this.templateToDelete != null) {
            this.$emit('delete-template', this.templateToDelete);
        }
    }
};
__decorate([
    Prop({ type: Array, default: () => [] })
], TemplateList.prototype, "templates", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], TemplateList.prototype, "isLoading", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], TemplateList.prototype, "hasMore", void 0);
__decorate([
    Prop({ type: String, default: undefined })
], TemplateList.prototype, "searchQuery", void 0);
TemplateList = __decorate([
    Component({
        components: {
            AppBar,
            TemplateListItemSkeleton,
            NoData,
            CopyLinkButton,
            DeleteButton,
            CopyButton,
            TemplateCopyDialog
        },
    })
], TemplateList);
export default TemplateList;
