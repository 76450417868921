import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import DeleteButton from "../../DeleteButton.vue";
import MoveButton from "../../MoveButton.vue";
import ChoiceTypeAutoComplete from "./ChoiceTypeAutoComplete.vue";
import InspectionListChoiceValueAutoComplete from "./InspectionListChoiceValueAutoComplete.vue";
let ChoiceInspection = class ChoiceInspection extends Vue {
    isLoadingChoiceTypes = 0;
    value;
    hover;
    onUpdateChoiceValue(choiceValue) {
        if (this.value.choiceInspection) {
            if (choiceValue) {
                this.value.choiceInspection.valueId = choiceValue.id;
            }
            else {
                this.value.choiceInspection.valueId = 0;
            }
        }
        return this.value;
    }
    onClearChoiceValue() {
        this.onUpdateChoiceValue(undefined);
    }
};
__decorate([
    Prop({ type: Object, required: true, default: null })
], ChoiceInspection.prototype, "value", void 0);
__decorate([
    Prop({ type: Boolean, required: false, default: false })
], ChoiceInspection.prototype, "hover", void 0);
__decorate([
    Emit('change')
], ChoiceInspection.prototype, "onUpdateChoiceValue", null);
ChoiceInspection = __decorate([
    Component({
        components: {
            MoveButton,
            DeleteButton,
            ChoiceTypeAutoComplete,
            InspectionListChoiceValueAutoComplete
        },
    })
], ChoiceInspection);
export default ChoiceInspection;
