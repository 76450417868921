import { __decorate } from "tslib";
import AppBar from "@/components/AppBar.vue";
import CopyLinkButton from "@/components/CopyLinkButton.vue";
import UndoSubmitButton from "@/components/UndoSubmitButton.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import NoData from "../NoData.vue";
import InspectionListItemSkeleton from "./InspectionListItemSkeleton.vue";
import gql from 'graphql-tag';
import { trackAction } from "@/store/Helpers/UserMonitor";
import AppBarResponsiveButton from "../AppBarResponsiveButton.vue";
import axios from "axios";
import { generateApiUrl } from "@/Helpers/BaseUrl";
import mainOidc from '@/plugins/vue-oidc-client/idsrvAuth';
import { saveAs } from 'file-saver';
import { Getter } from "vuex-class";
import _ from 'lodash';
import InspectionListListItem from "./InspectionListListItem.vue";
let InspectionListList = class InspectionListList extends Vue {
    inspectionLists;
    isLoading;
    searchQuery;
    hasMore;
    userLanguage;
    isLoadingReport = false;
    selectedInspectionLists = [];
    get inspectionListsIncludingSelectedItems() {
        let selectedExcludingResults = _.differenceBy(this.selectedInspectionLists, this.inspectionLists, 'key');
        selectedExcludingResults = _.orderBy(selectedExcludingResults, ['key', 'id'], ['asc']);
        return [...selectedExcludingResults, ...this.inspectionLists];
    }
    toggleSelection(inspectionList) {
        if (!inspectionList.submitted)
            return;
        const idx = _.findIndex(this.selectedInspectionLists, ['key', inspectionList.key]);
        if (idx >= 0) {
            this.selectedInspectionLists.splice(idx, 1);
        }
        else {
            this.selectedInspectionLists.push(inspectionList);
        }
    }
    async exportSelectedInspections() {
        if (this.selectedInspectionLists.length == 0) {
            return;
        }
        this.isLoadingReport = true;
        try {
            var paramsString = `language=${this.userLanguage}`;
            this.selectedInspectionLists.forEach(selectedInspectionList => {
                if (paramsString) {
                    paramsString = paramsString + "&";
                }
                paramsString = paramsString + `keys=${encodeURIComponent(selectedInspectionList.key)}`;
            });
            const response = await axios
                .get(generateApiUrl(`inspections/Report?${paramsString}`), {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': mainOidc.isAuthenticated ? `Bearer ${mainOidc.accessToken}` : ''
                }
            });
            const blob = new Blob([response.data]);
            saveAs(blob, `${this.selectedInspectionLists[0].key}.pdf`);
            this.selectedInspectionLists = [];
        }
        finally {
            this.isLoadingReport = false;
        }
    }
    isSelected(inspectionList) {
        return _.findIndex(this.selectedInspectionLists, ['key', inspectionList.key]) >= 0;
    }
    openInspectionList(inspectionList) {
        this.$router.push(this.getInspectionListRoute(inspectionList));
    }
    getInspectionListRoute(inspectionList) {
        return { name: "InspectionSearchEdit", params: { key: inspectionList.key } };
    }
    loadMoreData(entries, observer, intersecting) {
        if (intersecting) {
            this.$emit('load-more-data');
        }
    }
    async onClearSubmitState(inspectionList) {
        try {
            trackAction("Revert submit inspection");
            //Only request updated id's and submit state. This will prevent overwriting any other pending user changes
            await this.$apollo.mutate({
                mutation: gql `mutation ($input: ClearInspectionListSubmitStateInput!) {
            updatedInspectionList: clearInspectionListSubmitState(input: $input) {
              inspectionList {
                id
                submitted
              }
            }
          }`,
                variables: {
                    input: { inspectionListKey: inspectionList.key }
                }
            });
            if (this.isSelected(inspectionList)) {
                this.toggleSelection(inspectionList);
            }
        }
        catch (error) {
            console.log("Mutation clear submit state error");
        }
    }
};
__decorate([
    Prop({ type: Array, default: () => [] })
], InspectionListList.prototype, "inspectionLists", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], InspectionListList.prototype, "isLoading", void 0);
__decorate([
    Prop({ type: String, required: false, default: "" })
], InspectionListList.prototype, "searchQuery", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], InspectionListList.prototype, "hasMore", void 0);
__decorate([
    Getter
], InspectionListList.prototype, "userLanguage", void 0);
InspectionListList = __decorate([
    Component({
        components: {
            AppBar,
            InspectionListItemSkeleton,
            CopyLinkButton,
            UndoSubmitButton,
            NoData,
            AppBarResponsiveButton,
            InspectionListListItem
        },
    })
], InspectionListList);
export default InspectionListList;
