import { __decorate } from "tslib";
import AppBar from "@/components/AppBar.vue";
import { trackAction } from "@/store/Helpers/UserMonitor";
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import DeleteButton from "../DeleteButton.vue";
import NoData from "../NoData.vue";
import TemplateChoiceTypeListItemSkeleton from "./TemplateChoiceTypeListItemSkeleton.vue";
let ChoiceTypeList = class ChoiceTypeList extends Vue {
    choiceTypes;
    isLoading;
    hasMore;
    searchQuery;
    showChoiceTypeDeleteDialog = false;
    choiceTypeToDelete;
    showDeleteDialog(choiceType) {
        this.choiceTypeToDelete = choiceType;
        this.showChoiceTypeDeleteDialog = true;
        trackAction("Show delete choice type dialog");
    }
    openchoiceType(choiceType) {
        this.$router.push(this.getChoiceTypeRoute(choiceType));
    }
    getChoiceTypeRoute(choiceType) {
        return { name: "TemplateChoiceTypeEdit", params: { id: choiceType.id.toString() } };
    }
    getCols(isHovering) {
        return isHovering ? 10 : 11;
    }
    get supportTouch() {
        return ('ontouchstart' in window);
    }
    addChoiceType() {
        return this;
    }
    loadMoreData(entries, observer, intersecting) {
        if (intersecting) {
            return this;
        }
    }
    deleteChoiceType() {
        if (this.choiceTypeToDelete != null) {
            return this.choiceTypeToDelete;
        }
    }
};
__decorate([
    Prop({ type: Array, default: () => [] })
], ChoiceTypeList.prototype, "choiceTypes", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], ChoiceTypeList.prototype, "isLoading", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], ChoiceTypeList.prototype, "hasMore", void 0);
__decorate([
    Prop({ type: String, default: undefined })
], ChoiceTypeList.prototype, "searchQuery", void 0);
__decorate([
    Emit('add-template-choice-type')
], ChoiceTypeList.prototype, "addChoiceType", null);
__decorate([
    Emit('load-more-data')
], ChoiceTypeList.prototype, "loadMoreData", null);
__decorate([
    Emit('delete-template-choice-type')
], ChoiceTypeList.prototype, "deleteChoiceType", null);
ChoiceTypeList = __decorate([
    Component({
        components: {
            AppBar,
            TemplateChoiceTypeListItemSkeleton,
            NoData,
            DeleteButton
        },
    })
], ChoiceTypeList);
export default ChoiceTypeList;
