import { WebStorageStateStore } from 'oidc-client';
import Vue from 'vue';
import { createOidcAuth, SignInType, LogLevel } from 'vue-oidc-client/vue2';
import { datadogRum } from '@datadog/browser-rum';
const loco = window.location;
const appRootUrl = `${loco.protocol}//${loco.host}${process.env.BASE_URL}`;
const oidc_host = process.env.VUE_APP_OIDC_HOST || 'https://oidc:44380/';
const idsrvAuth = createOidcAuth('main', SignInType.Window, appRootUrl, {
    authority: oidc_host,
    client_id: 'inspections_spa',
    response_type: 'code',
    scope: 'openid profile roles offline_access',
    userStore: new WebStorageStateStore({ store: window.sessionStorage })
}, console, LogLevel.Info);
// handle events
idsrvAuth.events.addAccessTokenExpiring(function () {
    // eslint-disable-next-line no-console
    console.log('access token expiring');
});
idsrvAuth.events.addAccessTokenExpired(function () {
    // eslint-disable-next-line no-console
    console.log('access token expired');
    idsrvAuth.startSilentRenew();
});
idsrvAuth.events.addSilentRenewError(function (err) {
    // eslint-disable-next-line no-console
    console.error('silent renew error', err);
});
idsrvAuth.events.addUserLoaded(function (user) {
    // eslint-disable-next-line no-console
    datadogRum.setUser({ id: user.profile.org, name: user.profile.org });
    console.log('user loaded', user);
});
idsrvAuth.events.addUserUnloaded(function () {
    // eslint-disable-next-line no-console
    console.log('user unloaded');
});
idsrvAuth.events.addUserSignedOut(function () {
    // eslint-disable-next-line no-console
    console.log('user signed out');
});
idsrvAuth.events.addUserSessionChanged(function () {
    // eslint-disable-next-line no-console
    console.log('user session changed');
});
// a little something extra
Vue.prototype.$oidc = idsrvAuth;
export default idsrvAuth;
