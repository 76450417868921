import lowerBound from "./lowerbound";
export var QueueState;
(function (QueueState) {
    QueueState["NEW"] = "new";
    QueueState["PENDING"] = "pending";
    QueueState["DONE"] = "done";
    QueueState["ERROR"] = "error";
})(QueueState || (QueueState = {}));
export default class PromiseQueue {
    static REMOVE_DONE_ITEM_TIMEOUT = 5000;
    queue = [];
    pendingPromise = false;
    workingOnPromise = false;
    enqueue(run, options, replaceExisting = false) {
        options = {
            priority: 0,
            ...options,
        };
        const element = {
            priority: options.priority,
            description: options.description,
            dataObject: options.dataObject,
            state: QueueState.NEW,
            run,
        };
        if (replaceExisting) {
            this.removeExistingForDataObject(element.dataObject);
        }
        if (this.size && options.priority) {
            const lastitem = this.queue[this.size - 1];
            if (lastitem && lastitem.priority && lastitem.priority >= options.priority) {
                this.queue.push(element);
                this.dequeue();
                return;
            }
        }
        const index = lowerBound(this.queue, element, (a, b) => (b.priority && a.priority) ? b.priority - a.priority : 0);
        this.queue.splice(index, 0, element);
        this.dequeue();
    }
    //Remove all queue items for this data object that are not currently pending
    removeExistingForDataObject(dataObject) {
        this.queue = this.queue.filter(item => item.dataObject !== dataObject || item.state === QueueState.PENDING || item.state === QueueState.DONE);
    }
    dequeue() {
        if (this.workingOnPromise) {
            return false;
        }
        const item = this.getNextItem();
        if (!item) {
            return false;
        }
        try {
            this.workingOnPromise = true;
            item.run()
                .then((_value) => {
                this.workingOnPromise = false;
                item.state = QueueState.DONE;
                this.scheduleRemove(item);
                this.dequeue();
            })
                .catch((_err) => {
                this.workingOnPromise = false;
                item.state = QueueState.ERROR;
                //this.scheduleRemove(item);
                this.dequeue();
            });
        }
        catch (err) {
            this.workingOnPromise = false;
            item.state = QueueState.ERROR;
            //this.scheduleRemove(item);
            this.dequeue();
        }
        return true;
    }
    scheduleRemove(item) {
        setTimeout(() => {
            const idx = this.queue.indexOf(item);
            this.queue.splice(idx, 1);
        }, PromiseQueue.REMOVE_DONE_ITEM_TIMEOUT);
    }
    get size() {
        return this.queue.length;
    }
    getNextItem() {
        const item = this.queue.find(element => element.state === QueueState.NEW);
        if (item) {
            item.state = QueueState.PENDING;
        }
        return item;
    }
    get itemsInQueue() {
        return this.queue.filter(element => element.state !== QueueState.DONE).length;
    }
    clear() {
        this.queue = [];
    }
    get hasErrors() {
        return this.queue.filter(element => element.state === QueueState.ERROR).length > 0;
    }
    retryErrors() {
        this.queue.forEach(item => {
            if (item.state === QueueState.ERROR) {
                item.state = QueueState.NEW;
            }
        });
        this.dequeue();
    }
}
