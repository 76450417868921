import { __decorate } from "tslib";
import TemplateInspectionGroupComponent from "./TemplateInspectionGroup.vue";
import InspectionTitleEdit from "@/components/InspectionList/InspectionTitleEdit.vue";
import { TemplateUpdate } from "@/types/Api/Template/Update/TemplateUpdate";
import gql from "graphql-tag";
import _ from "lodash";
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";
import draggable from 'vuedraggable';
import { State } from "vuex-class";
import TCHover from "../TrivestComponents/TCHover/TCHover.vue";
let TemplateEditForm = class TemplateEditForm extends Vue {
    template;
    isLoadingData;
    debouncedSaveFn;
    titleEdit;
    validate() {
        return this.titleEdit.validate();
    }
    onTemplateChanged() {
        if (this.debouncedSaveFn)
            this.debouncedSaveFn();
    }
    created() {
        this.debouncedSaveFn = _.debounce(this.doSave, 2000, { trailing: true });
    }
    doSave() {
        if (!this.validate()) {
            return;
        }
        let updateObject = new TemplateUpdate(this.template, false);
        this.$promiseQueue.enqueue(() => {
            return new Promise((resolve, reject) => {
                this.$apollo.mutate({
                    mutation: gql `mutation ($input: UpdateTemplateInput!) 
          {
            updatedTemplate: updateTemplate(input: $input) {
              template {
                id
                description
              }
            }
          }`,
                    variables: {
                        input: { template: updateObject }
                    },
                    fetchPolicy: "no-cache"
                }).then(result => {
                    resolve(this.template);
                }).catch(error => {
                    reject(error);
                });
            });
        }, {
            dataObject: this.template,
            description: this.$t('update-template').toString(),
        }, true);
    }
};
__decorate([
    Prop({ type: Object, default: null })
], TemplateEditForm.prototype, "template", void 0);
__decorate([
    State
], TemplateEditForm.prototype, "isLoadingData", void 0);
__decorate([
    Ref("titleEdit")
], TemplateEditForm.prototype, "titleEdit", void 0);
__decorate([
    Watch('template.description', { deep: true })
], TemplateEditForm.prototype, "onTemplateChanged", null);
TemplateEditForm = __decorate([
    Component({
        components: {
            InspectionTitleEdit,
            draggable,
            TCHover,
            TemplateInspectionGroupComponent
        }
    })
], TemplateEditForm);
export default TemplateEditForm;
