import { __decorate } from "tslib";
import AppBarResponsiveButton from "@/components/AppBarResponsiveButton.vue";
import TrivestSearchField from '@/components/TrivestComponents/TrivestSearchField/TrivestSearchField.vue';
import InspectionsSearchItemSkeleton from "./InspectionSearchItemSkeleton.vue";
import InspectionListHighlighter from '@/components/InspectionList/InspectionListHighlighter.vue';
import NoData from "@/components/NoData.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
let InspectionsSearch = class InspectionsSearch extends Vue {
    isLoading;
    inspectionLists;
    searchQuery;
    hasMore;
    openInspectionList(inspectionList) {
        this.$router.push({ name: "InspectionSearchEdit", params: { key: inspectionList.key, from: 'search' } });
    }
    loadMoreData(entries, observer, intersecting) {
        if (intersecting) {
            this.$emit('load-more-data');
        }
    }
};
__decorate([
    Prop({ type: Boolean, default: false })
], InspectionsSearch.prototype, "isLoading", void 0);
__decorate([
    Prop({ type: Array, default: () => [] })
], InspectionsSearch.prototype, "inspectionLists", void 0);
__decorate([
    Prop({ type: String, required: false, default: "" })
], InspectionsSearch.prototype, "searchQuery", void 0);
__decorate([
    Prop({ type: Boolean, default: true })
], InspectionsSearch.prototype, "hasMore", void 0);
InspectionsSearch = __decorate([
    Component({
        components: {
            TrivestSearchField,
            AppBarResponsiveButton,
            InspectionListHighlighter,
            InspectionsSearchItemSkeleton,
            NoData
        },
    })
], InspectionsSearch);
export default InspectionsSearch;
