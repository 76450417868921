import { __decorate } from "tslib";
import AppBarResponsiveButton from "@/components/AppBarResponsiveButton.vue";
import ChoiceTypeList from "@/components/ChoiceTypes/ChoiceTypeList.vue";
import DefaultMenu from "@/components/DefaultMenu.vue";
import TemplateAddDialog from "@/components/Templates/TemplateAddDialog.vue";
import TemplateEditView from "@/components/Templates/TemplateEditView.vue";
import { trackAction } from "@/store/Helpers/UserMonitor";
import { TemplateChoiceTypeCollectionSegment } from "@/types/Api/Template/TemplateCollectionSegments";
import { Component, Prop, Vue } from "vue-property-decorator";
import gql from 'graphql-tag';
import ChoiceTypeAddDialog from "@/components/ChoiceTypes/ChoiceTypeAddDialog.vue";
const PAGESIZE = 20;
const PAGESIZELOCALSTORAGEKEY = 'pagesize';
let TemplateChoiceTypesView = class TemplateChoiceTypesView extends Vue {
    templateChoiceTypeCollection = new TemplateChoiceTypeCollectionSegment();
    isLoading = 0;
    searchQuery;
    showChoiceTypeAddDialog = false;
    get query() {
        if (!this.searchQuery)
            return null;
        return this.searchQuery;
    }
    fetchMore() {
        this.$apollo.queries.templateChoiceTypeCollection.fetchMore({
            variables: {
                skip: this.templateChoiceTypeCollection.items.length
            }, updateQuery: (previousResult, { fetchMoreResult }) => {
                fetchMoreResult.templateCollection.items = [...previousResult.templateCollection.items, ...fetchMoreResult.templateCollection.items];
                return fetchMoreResult;
            }
        });
    }
    addChoiceType() {
        this.showChoiceTypeAddDialog = true;
        trackAction("Show choice add dialog");
    }
    async deleteChoiceType(choiceType) {
        this.$promiseQueue.enqueue(() => {
            return new Promise((resolve, reject) => {
                this.$apollo.mutate({
                    mutation: gql `mutation ($input: DeleteTemplateChoiceTypeInput!) {
              resultData: deleteTemplateChoiceType(input: $input) {
                templateChoiceType {
                  id            
                }
              }
            }`,
                    variables: {
                        input: { templateChoiceTypeId: Number(choiceType.id) }
                    }
                }).then(result => {
                    //todo: Remove item from cache?
                    this.$apollo.queries.templateChoiceTypeCollection.refetch({
                        skip: 0,
                        query: this.query,
                        take: localStorage.getItem(PAGESIZELOCALSTORAGEKEY) || PAGESIZE
                    });
                    resolve(choiceType);
                }).catch(error => {
                    resolve(error);
                });
            });
        }, { dataObject: choiceType, description: 'Deleting choice type' }, true);
        trackAction("Delete choice type");
    }
};
__decorate([
    Prop({ type: String, required: false, default: "" })
], TemplateChoiceTypesView.prototype, "searchQuery", void 0);
TemplateChoiceTypesView = __decorate([
    Component({
        components: {
            TemplateEditView,
            AppBarResponsiveButton,
            ChoiceTypeList,
            DefaultMenu,
            TemplateAddDialog,
            ChoiceTypeAddDialog
        },
        apollo: {
            templateChoiceTypeCollection: {
                query: gql `query templateChoiceTypeCollection($skip: Int!, $take: Int!, $query: String){
        templateChoiceTypeCollection: templateChoiceTypes(skip: $skip, take: $take
          where: {
            or: [ { description: { contains: $query } }]
          }) {
            items {
              id
              description
            }
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            totalCount
          }
        }`,
                variables() {
                    return {
                        skip: 0,
                        take: localStorage.getItem(PAGESIZELOCALSTORAGEKEY) || PAGESIZE,
                        query: this.query
                    };
                },
                fetchPolicy: 'cache-and-network',
                loadingKey: 'isLoading'
            }
        }
    })
], TemplateChoiceTypesView);
export default TemplateChoiceTypesView;
