import { __decorate } from "tslib";
import InspectionTitle from "@/components/InspectionList/InspectionTitle.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import InspectionListGroup from "./InspectionListGroup.vue";
import gql from "graphql-tag";
import InspectionListAttachments from "./InspectionListAttachments.vue";
import { InspectionListInspectionUpdate } from "@/types/Api/InspectionList/Update/InspectionListInspectionUpdate";
import { trackAction } from "@/store/Helpers/UserMonitor";
let InspectionListEditForm = class InspectionListEditForm extends Vue {
    inspectionList;
    onChange(inspection) {
        this.doSave(inspection);
    }
    doSave(inspection) {
        if (!inspection) {
            console.error("No inspection provided!");
            return;
        }
        let updateObject = new InspectionListInspectionUpdate(inspection);
        let description = this.getDescription(inspection);
        this.$promiseQueue.enqueue(() => {
            return new Promise((resolve, reject) => {
                this.$apollo.mutate({
                    mutation: gql `mutation ($input: UpdateInspectionListInspectionInput!) 
          {
            updatedInspection: updateInspectionListInspection(input: $input) {
              inspectionListInspection {
                id
              }
            }
          }`,
                    variables: {
                        input: { inspectionListInspection: updateObject }
                    },
                    fetchPolicy: "no-cache"
                }).then(result => {
                    resolve(inspection);
                }).catch(error => {
                    reject(error);
                });
            });
        }, {
            dataObject: inspection,
            description: description,
        }, true);
    }
    getDescription(inspection) {
        let itemDescription = inspection.booleanInspection?.description ??
            inspection.multiLineInspection?.description ??
            inspection.singleLineInspection?.description ??
            inspection.numericalInspection?.description ??
            inspection.imageInspection?.description ??
            inspection.choiceInspection?.description ??
            inspection.dateInspection?.description;
        if (!itemDescription)
            itemDescription = this.$t('inspectionTitle').toString();
        let description = this.$t('saving-inspection-description', [itemDescription]).toString();
        return description;
    }
    async onSubmit() {
        this.inspectionList.submitted = true;
        trackAction("Submit inspection");
        this.$promiseQueue.enqueue(() => {
            return new Promise((resolve, reject) => {
                this.$apollo.mutate({
                    mutation: gql `mutation ($input: SetInspectionListSubmitStateInput!) {
            updatedInspectionList: setInspectionListSubmitState(input: $input) {
              inspectionList {
                id
                submitted
              }
            }
          }`,
                    variables: {
                        input: { inspectionListKey: this.inspectionList.key }
                    },
                }).then(result => {
                    this.inspectionList.submitted = true;
                    resolve(this.inspectionList);
                }).catch(error => {
                    this.inspectionList.submitted = false;
                    console.error("Error submitting inspectionlist");
                    console.error(error);
                    reject(error);
                });
            });
        }, {
            dataObject: this.inspectionList,
            description: this.$t('submit-button').toString(),
        }, false);
    }
};
__decorate([
    Prop({ type: Object, default: null })
], InspectionListEditForm.prototype, "inspectionList", void 0);
InspectionListEditForm = __decorate([
    Component({
        components: {
            InspectionTitle,
            InspectionListGroup,
            InspectionListAttachments
        }
    })
], InspectionListEditForm);
export default InspectionListEditForm;
